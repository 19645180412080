import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, viewChild, ViewChild } from '@angular/core';
import { Enums, Types } from '@cornerstonejs/core';
import { CornerstoneService, Threads } from '@app/@core';
import { DEFAULT_PANEL_INDEX } from '@app/viewer/contants';
import { IPanelLayout } from '@app/viewer/models';
import { BroadcastService, ToolsService } from '@app/viewer/services';
import { ViewerLayoutQuery } from '@app/viewer/store/layout';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
const { IMAGE_RENDERED } = Enums.Events;
export interface IPanelViewport {
  panelIndex: string;
  panelLayout: string;
  index: number;
}
@Component({
  selector: 'app-viewer-viewport',
  templateUrl: './viewport.component.html',
  styleUrl: './viewport.component.scss',
})
export class ViewerViewportComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private store: Store,
    private toolsService: ToolsService,
    private cornerstoneService: CornerstoneService,
    private boardcastService: BroadcastService,
  ) {}

  private _selectPanelSubs$: Subscription;

  public panelLayout$: Observable<IPanelLayout>;
  public panelLayout: IPanelViewport[];
  public layout: string;
  public currentActivePanel$: Observable<string | undefined>;
  public currentActivePanel: string;
  private _metadataMenuSubs$: Subscription;
  private _displayMetadata$: Observable<boolean>;
  private _resizeTimeout: any;
  private _resizeObserver: ResizeObserver;
  @ViewChild('viewport') viewportContainer: ElementRef;

  ngOnInit(): void {
    this._resizeObserver = new ResizeObserver(this.onWindowsResize);
    this.toolsService.initDefautlToolStack();
    this.panelLayout$ = this.store.select(ViewerLayoutQuery.selectPanelLayout);
    this._displayMetadata$ = this.store.select(ViewerLayoutQuery.selectMetadataDisplay);
    this._metadataMenuSubs$ = this._displayMetadata$.subscribe((value) => {
      this._onResizeRender();
    });
    this.panelLayout$.subscribe((value: IPanelLayout) => {
      if (this.panelLayout) {
        this.panelLayout.length = 0;
      }
      const layout: IPanelViewport[] = [];
      for (let index = 0; index < value.total; index++) {
        const panel: IPanelViewport = {
          panelIndex: value.id,
          panelLayout: value.layoutString,
          index: index,
        };
        layout.push(panel);
      }
      this.panelLayout = layout;
    });
    this.currentActivePanel$ = this.store.select(ViewerLayoutQuery.selectCurrentActivePanelIndex);
    this._selectPanelSubs$ = this.currentActivePanel$.subscribe((value) => {
      this.currentActivePanel = value || DEFAULT_PANEL_INDEX;
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this._resizeObserver.observe(this.viewportContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this._selectPanelSubs$?.unsubscribe();
    this._metadataMenuSubs$?.unsubscribe();
  }

  protected onWindowsResize = async () => {
    if (this._resizeTimeout) {
      return;
    }
    //Wait for DOM rerender grid view with delay time
    this._resizeTimeout = setTimeout(this._onResizeRender, 100);
  };

  /**
   * Handles the resize event and re-renders the viewports.
   *
   * This asynchronous function retrieves the rendering engine and all stack viewports
   * from the `cornerstoneService`. If there are no viewports, it exits early. Otherwise,
   * it captures the current view presentations of each viewport, resizes the rendering
   * engine, and then restores the view presentations to each viewport before triggering
   * a re-render.
   *
   * @private
   * @returns {Promise<void>} A promise that resolves when the resize and render operations are complete.
   */
  private _onResizeRender = async () => {
    this._resizeTimeout = null;
    const renderingEngine = this.cornerstoneService.getRenderEngine();
    if (renderingEngine) {
      const viewports = this.cornerstoneService.getAllStackViewport();
      if (viewports.length === 0) {
        return;
      }
      const presentations = viewports.map((viewport) => viewport.getViewPresentation());
      renderingEngine.resize(true, true);
      viewports.forEach((viewport, idx) => {
        viewport.setViewPresentation(presentations[idx]);
        viewport.render();
      });
    }
  };
}
