import { createFeature, createReducer, on } from '@ngrx/store';
import * as WorklistActions from './worklist.actions';
import { IStudyInfo } from '../models/IStudyInfo';
import { IWorklistData } from '../models/IDatatable';
import { mutableOn } from 'ngrx-etc';
import { ISearch } from '../models/ISearch';
export const WORKLIST_KEY = 'worklist';

/**
 * Represents the state of the worklist in the application.
 */
export interface WorklistState {
  /**
   * Indicates whether the worklist is currently being loaded.
   */
  isLoading: boolean;

  totalRecords: number;
  /**
   * The array of study information in the worklist.
   */
  worklist: IWorklistData[];

  search: ISearch | null;

  isOpenReport: boolean;

  /**
   * The error that occurred while loading the worklist.
   */
  error: any;
}

export const initialState: WorklistState = {
  worklist: [],
  error: null,
  isLoading: true,
  isOpenReport: false,
  search: null,
  totalRecords: 0,
};

//#region  reducer
/**
 * Represents the feature configuration for the worklist module.
 */
export const worklistFeature = createFeature({
  name: WORKLIST_KEY,
  reducer: createReducer<WorklistState>(
    initialState,
    on(
      WorklistActions.loadWorklist,
      (state): WorklistState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      WorklistActions.loadWorklistSuccess,
      (state, action): WorklistState => ({
        ...state,
        isLoading: false,
        worklist: action.worklist,
        totalRecords: action.totalRecords,
      }),
    ),
    on(
      WorklistActions.loadWorklistFailure,
      (state, action): WorklistState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    on(
      WorklistActions.openReport,
      (state, action): WorklistState => ({
        ...state,
        isOpenReport: true,
      }),
    ),
    on(
      WorklistActions.clearSearchPayload,
      (state, action): WorklistState => ({
        ...state,
        search: null,
      }),
    ),
    mutableOn(WorklistActions.updateSearchPayload, (state, action): WorklistState => {
      if (action.payload === null) {
        state.search = null;
        return state;
      }
      state.search = { ...state.search, ...action.payload };
      return state;
    }),
    on(
      WorklistActions.closeReport,
      (state, action): WorklistState => ({
        ...state,
        isOpenReport: false,
      }),
    ),
    mutableOn(WorklistActions.shareStudySuccess, (state, action): WorklistState => {
      const updateWorklist = state.worklist.find((worklist) => worklist.id === action.study.id);
      if (updateWorklist) {
        updateWorklist.isShared = action.study.isShared;
      }
      return state;
    }),
    mutableOn(WorklistActions.unshareStudySuccess, (state, action): WorklistState => {
      const updateWorklist = state.worklist.find((worklist) => worklist.id === action.study.id);
      if (updateWorklist) {
        updateWorklist.isShared = action.study.isShared;
      }
      return state;
    }),
  ),
});

//#endregion

export const {
  name, // feature name
  reducer, // feature reducer
} = worklistFeature;
