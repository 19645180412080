import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FIREBASE_FILTER_TYPE, getDateTimeFormat, IGeneralStudy } from '@app/@core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { Table } from 'primeng/table';
import { ViewerGeneralQuery, studyListAction, viewerGeneralActions } from '@app/viewer/store/general';
import { DEFAULT_SETTING } from '@app/viewer/contants/default-setting';
import { ILayoutModalityItem, settingQuery } from '@app/setting';
import { ViewerLayoutQuery } from '@app/viewer/store/layout';
import { DownloadQueueService } from '@app/viewer/services/download-queue.service';
import { Severity, SharedService } from '@app/@shared';
// import { environment } from '@env/environment';
@Component({
  selector: 'app-viewer-study-list',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
})
/**
 * Represents the Viewer Study Component.
 * This component is responsible for displaying study information and handling user interactions.
 */
export class ViewerStudyComponent implements OnInit, AfterViewInit, OnDestroy {
  //#region Props
  public totalRecord: number = 0;
  public tableData$: Observable<IGeneralStudy[]>;
  public filterButton$: Observable<string[]>;
  public tableDataSub: Subscription;
  public modality: string[];
  public curModality: string = 'All';
  protected cols: any[] = [];
  protected dateTimeFormat: string = 'YYYY-MM-DD';
  public selectedStudies$: Observable<IGeneralStudy[]>;
  @Output() selectedStudyEvent = new EventEmitter<IGeneralStudy>();
  @ViewChild('datatable') datatableView: ElementRef;
  @ViewChild('dt') dt: Table;
  selectedStudies: Subscription;
  public scrollHeight: string = '150px';
  protected moment: any = moment;
  protected cmItems: MenuItem[];
  //#endregion
  constructor(
    private translateService: TranslateService,
    private store: Store,
    private downloadQueue: DownloadQueueService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'uid', header: '', display: 'none' },
      { field: 'studyDate', header: 'studyInfoStudyDate', width: '30%', display: 'table-cell' },
      {
        field: 'modality',
        header: 'studyInfoModality',
        width: '20%',
        display: 'table-cell',
      },
      {
        field: 'bodyPart',
        header: 'studyInfoBodyPart',
        width: '20%',
        display: 'table-cell',
      },
      {
        field: 'totalSeries',
        header: 'studyInfoTotalSeries',
        width: '15%',
        display: 'table-cell',
      },
      {
        field: 'totalImage',
        header: 'studyInfoTotalImages',
        width: '15%',
        display: 'table-cell',
      },
    ];

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const lang = event.lang;
      this.dateTimeFormat = getDateTimeFormat(lang);
    });
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized the component's view.
   * It is called only once after the first ngAfterContentChecked().
   * Use this hook to perform any initialization logic that relies on the component's view.
   */
  ngAfterViewInit(): void {
    this.tableData$ = this.store.select(ViewerGeneralQuery.selectStudyInfoList);
    this.filterButton$ = this.store.select(ViewerGeneralQuery.selectModalityFilter);
    this.selectedStudies$ = this.store.select(ViewerGeneralQuery.selectSelectedStudies);
    // Work around the table not fire event when 1st selection and change when selected multi row
    this.selectedStudies = this.selectedStudies$.subscribe((value) => {
      if (value.length === 1) {
        this.selectedStudyEvent.emit(value[0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedStudies.unsubscribe();
  }

  /**
   * Handles the change event when the selection changes.
   * @param $event The event object containing the new selection.
   */
  public onSelectionChange = ($event) => {
    this.store.dispatch(viewerGeneralActions.changeSelectedStudy({ payload: $event }));
  };

  /**
   * Filters the study by the specified modality.
   *
   * @param modality - The modality to filter by.
   */
  protected onFilterModality = (modality: string) => {
    this.curModality = modality;
    this.dt.clearFilterValues();
    // Apply fileter modality
    if (modality === 'All') {
      this.dt.clear();
      return;
    }
    this.dt.filter(modality, this.cols[2].field, FIREBASE_FILTER_TYPE.CONTAINS);
  };

  /**
   * Handles the change event of the context menu selection.
   * Generate menu item base on selected study
   * @param $event The event object.
   */
  protected onContextMenuSelectionChange = async ($event) => {
    //Get current selected study on store
    const currentSelectedStudy: IGeneralStudy[] = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSelectedStudies));
    //Store is read only, so clone it to new array
    const selection = cloneDeep(currentSelectedStudy);
    //Sort study by uid for correct order in list
    this._sortSelectionByID(selection);
    //Get default modality of selected study. default is first study after sort
    const modality = selection[0].modality;

    // Get layout setting by modality on database
    const layoutSetting = await firstValueFrom(this.store.select(settingQuery.selectLayoutByModality(modality)));

    // Create sub menu name base on layout setting of modality
    const menuName: string[] = [];
    for (let key in layoutSetting) {
      if (layoutSetting.hasOwnProperty(key)) {
        menuName.push(layoutSetting[key].name);
      }
    }

    // Create submenu of context menu change layout by modality setting
    let menuText: MenuItem;
    let subMenu: MenuItem[] = [];
    for (let i = 0, length = menuName.length; i < length; i++) {
      menuText = {
        label: menuName[i],
        icon: '',
        command: (event) => this._studyDisplayBySetting(menuName[i] || '', modality, selection),
      };
      subMenu.push(menuText);
    }

    // Generate context menu item base on selected study and set text with submenu
    this.cmItems = [];
    // case add context menu items when single select row.
    if (selection.length === 1) {
      this.cmItems = [
        {
          label: this.translateService.instant('StudyDisplayCurrentLayout'),
          icon: '',
          command: (event) => this._studyDisplayCurrentLayout(selection),
        },
        {
          label: this.translateService.instant('StudyDisplayAllSeries'),
          icon: '',
          command: (event) => this._studyDisplayAllSeriesofSelectedStudyAutomatically(selection),
        },
        {
          label: this.translateService.instant('StudyDisplayBySetting'),
          items: subMenu,
        },
        // {
        //   label: this.translateService.instant('StudyDisplayLastStack'),
        //   icon: '',
        //   //command: (event) => this.DisplaySelectedStudyonLastSelectedstack(),
        // },
        // { label: 'Display Selected Study into one stack', icon: '', command: (event) => this.handleFilterButton(1) },
      ];
    } else if (selection.length <= 3) {
      // case add context menu items when multi select row and less than 3 row.
      this.cmItems = [
        {
          label: this.translateService.instant('StudyDisplayCurrentLayout'),
          icon: '',
          command: (event) => this._studyDisplayCurrentLayout(selection),
        },
        {
          label: this.translateService.instant('StudyDisplayAllSeries'),
          icon: '',
          command: (event) => this._studyDisplayAllSeriesofSelectedStudyAutomatically(selection),
        },
        {
          label: this.translateService.instant('StudyDisplayBySetting'),
          items: subMenu,
        },
      ];
    }
    // Case add context menu items when muilti select row.
    else {
      this.cmItems = [
        {
          label: this.translateService.instant('StudyDisplayCurrentLayout'),
          icon: '',
          command: (event) => this._studyDisplayCurrentLayout(selection),
        },
        {
          label: this.translateService.instant('StudyDisplayBySetting'),
          items: subMenu,
        },
      ];
    }
  };

  /**
   * Sorts an array of IGeneralStudy objects by their uid property in ascending order.
   *
   * @param array - The array of IGeneralStudy objects to be sorted.
   * @returns The sorted array of IGeneralStudy objects.
   */
  private _sortSelectionByID(array: Array<IGeneralStudy>) {
    array.sort((a, b) => {
      if (a.uid < b.uid) {
        return -1;
      } else if (a.uid > b.uid) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  /**
   * Displays the current layout for the selected studies.
   *
   * @param selectedStudies - An array of selected studies.
   * @returns A Promise that resolves when the layout is displayed.
   */
  private _studyDisplayCurrentLayout = async (selectedStudies: IGeneralStudy[]) => {
    if (this.downloadQueue.isDownloading()) {
      this.sharedService.toastMessage(Severity.warn, this.translateService.instant('DownloadingNotChangeLayout'));
      return;
    }
    this.store.dispatch(studyListAction.studyDisplayCurrentLayout({ studyUid: selectedStudies.map((study) => study.uid) }));
  };

  private _studyDisplayAllSeriesofSelectedStudyAutomatically = async (selectedStudies: IGeneralStudy[]) => {
    if (this.downloadQueue.isDownloading()) {
      this.sharedService.toastMessage(Severity.warn, this.translateService.instant('DownloadingNotChangeLayout'));
      return;
    }
    if (selectedStudies.length > 3) {
      this.sharedService.toastMessage(Severity.warn, this.translateService.instant('MaxStudyDisplayAllSeries'));
      return;
    }

    this.store.dispatch(studyListAction.studyDisplayAllSeries({ studyUids: selectedStudies.map((study) => study.uid) }));
  };

  /**
   * Displays the studies based on the specified setting and modality.
   *
   * @param settingName - The name of the setting.
   * @param modality - The modality of the studies.
   * @param selectedStudies - The array of selected studies.
   */
  private _studyDisplayBySetting = async (settingName: string, modality: string, selectedStudies: IGeneralStudy[]) => {
    if (this.downloadQueue.isDownloading()) {
      this.sharedService.toastMessage(Severity.warn, this.translateService.instant('DownloadingNotChangeLayout'));
      return;
    }
    this.store.dispatch(studyListAction.studyDisplayBySetting({ settingName, modality, selectedStudies }));
  };
}
