/**
 * Removes items from `arrayA` that are present in `arrayB`.
 *
 * @param arrayA - The array from which items will be removed.
 * @param arrayB - The array containing items to be removed from `arrayA`.
 * @returns A new array containing items from `arrayA` that are not in `arrayB`.
 */
const removeMatchingItemsSet = (arrayA: any[], arrayB: any[]) => {
  const setB = new Set(arrayB);
  const result = arrayA.filter((item) => !setB.has(item));
  return result;
};

export { removeMatchingItemsSet };
