import { CornerstoneMetadata } from '@app/@core';
import { Enums, metaData } from '@cornerstonejs/core';
import { utilities } from '@cornerstonejs/tools';
import { vec3 } from 'gl-matrix';
const { getOrientationStringLPS, invertOrientationStringLPS } = utilities.orientation;

/**
 *
 * Computes the orientation labels on a Cornerstone-enabled Viewport element
 * when the viewport settings change (e.g. when a horizontal flip or a rotation occurs)
 *
 * @param {*} rowCosines
 * @param {*} columnCosines
 * @param {*} rotation in degrees
 * @returns
 */
const _getOrientationMarkers = (rowCosines, columnCosines, rotation, flipVertical, flipHorizontal) => {
  const rowString = getOrientationStringLPS(rowCosines);
  const columnString = getOrientationStringLPS(columnCosines);
  const oppositeRowString = invertOrientationStringLPS(rowString);
  const oppositeColumnString = invertOrientationStringLPS(columnString);

  const markers = {
    top: oppositeColumnString,
    left: oppositeRowString,
    right: rowString,
    bottom: columnString,
  };

  // If any vertical or horizontal flips are applied, change the orientation strings ahead of
  // the rotation applications
  if (flipVertical) {
    markers.top = invertOrientationStringLPS(markers.top);
    markers.bottom = invertOrientationStringLPS(markers.bottom);
  }

  if (flipHorizontal) {
    markers.left = invertOrientationStringLPS(markers.left);
    markers.right = invertOrientationStringLPS(markers.right);
  }

  // Swap the labels accordingly if the viewport has been rotated
  // This could be done in a more complex way for intermediate rotation values (e.g. 45 degrees)
  switch (true) {
    case rotation >= 90 && rotation < 180:
      return {
        top: invertOrientationStringLPS(markers.top),
        left: invertOrientationStringLPS(markers.left),
        bottom: invertOrientationStringLPS(markers.bottom),
        right: invertOrientationStringLPS(markers.right),
      };
    case rotation >= 180 && rotation < 270:
      return {
        top: markers.left,
        left: invertOrientationStringLPS(markers.top),
        right: invertOrientationStringLPS(markers.bottom),
        bottom: markers.right, // left
      };
    case rotation >= 270 && rotation < 360:
      return {
        top: invertOrientationStringLPS(markers.left),
        left: markers.top,
        bottom: markers.left,
        right: markers.bottom,
      };
  }
  // if (rotation === 90 || rotation === -270) {
  //   return {
  //     top: markers.left,
  //     left: invertOrientationStringLPS(markers.top),
  //     right: invertOrientationStringLPS(markers.bottom),
  //     bottom: markers.right, // left
  //   };
  // } else if (rotation === -90 || rotation === 270) {
  //   return {
  //     top: invertOrientationStringLPS(markers.left),
  //     left: markers.top,
  //     bottom: markers.left,
  //     right: markers.bottom,
  //   };
  // } else if (rotation === 180 || rotation === -180) {
  //   return {
  //     top: invertOrientationStringLPS(markers.top),
  //     left: invertOrientationStringLPS(markers.left),
  //     bottom: invertOrientationStringLPS(markers.bottom),
  //     right: invertOrientationStringLPS(markers.right),
  //   };
  // }

  return markers;
};

const viewportOrientationMarkers = (viewport, rotation, flipHorizontal, flipVertical) => {
  if (!viewport) {
    return '';
  }

  let rowCosines, columnCosines;

  if (viewport.type === Enums.ViewportType.STACK) {
    const imageId = viewport.getCurrentImageId();
    if (!imageId) {
      return '';
    }
    ({ rowCosines, columnCosines } = metaData.get(CornerstoneMetadata.IMAGE_PLANE, imageId) || {});
  } else {
    const { viewUp, viewPlaneNormal } = viewport.getCamera();
    if (viewUp === undefined || viewPlaneNormal === undefined) {
      return '';
    }
    const viewRight = vec3.create();
    vec3.cross(viewRight, viewUp, viewPlaneNormal);

    columnCosines = [-viewUp[0], -viewUp[1], -viewUp[2]];
    rowCosines = viewRight;
  }

  if (!rowCosines || !columnCosines || rotation === undefined) {
    return '';
  }

  const markers = _getOrientationMarkers(rowCosines, columnCosines, rotation, flipVertical, flipHorizontal);

  return markers;
};

export { viewportOrientationMarkers };
