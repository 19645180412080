import { AfterContentInit, Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FileHelpers, Logger, Severity, SharedService } from '@app/@shared';
import { TranslateService } from '@ngx-translate/core';
import { ALLOW_DCM_EXTENSION, DicomLoaderService, IStudy } from '@app/@core';
import { ProcessButtonType } from './constants/enum';
import { PreUploadService } from './services/pre-upload.service';
import { PreviewComponent } from './pages/preview/preview.component';
import { NgbScrollSpy } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@app/auth';
import { UploadService } from './services/upload.service';
import { IStudyUpload } from './models/IStudyUpload';
const log = new Logger('Worklist');
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements AfterContentInit, CanComponentDeactivate {
  //#region Properties
  public disableUploadButton = false;
  public disableResetButton = true;
  public files: File[] = [];
  public uploading: boolean = false;
  public previewitems: IStudyUpload[] = [];
  protected isOverrideData: boolean = false;
  protected uploadMessage: string = '';
  private _state = {
    rootFolderId: '',
    uploadIndex: 0,
  };
  @ViewChild('spy') spy!: NgbScrollSpy;
  @ViewChildren('previewChild') previewChildern: QueryList<PreviewComponent>;
  //#endregion

  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private dicomLoaderService: DicomLoaderService,
    private preUploadService: PreUploadService,
    private uploadService: UploadService,
  ) {}

  //#region Angular core
  ngAfterContentInit() {
    this.sharedService.preloader(false);
  }
  //#endregion

  /**
   * Handles the change event when files are selected.
   * @param event - The array of selected files.
   * @returns A promise that resolves when the preview is loaded.
   */
  public onFilesChange = async (event: any) => {
    let fileList = <File[]>event.target.files;
    fileList = [...fileList].filter((file) => {
      return FileHelpers.Instance.getExtension(file.name) === ALLOW_DCM_EXTENSION;
    });
    if (fileList.length > 0) {
      this.sharedService.preloader(true, this.translateService.instant('LoadUploadPreview'));
      this.files = fileList;
      await this.loadPreview();
      this.uploadMessage = this.translateService.instant('DataUploadStatus', {
        value: this.translateService.instant('DataUploadStatusNotYet'),
      });
      this.sharedService.preloader(false);
    }
  };
  /**
   * Handles the file drop event.
   * @param $event - The UploadEvent object containing the dropped files.
   * @returns A Promise that resolves when the preview is loaded.
   */
  public onFileDrop = async ($event) => {
    this.sharedService.preloader(true, this.translateService.instant('LoadUploadPreview'));
    this.files = $event;
    await this.loadPreview();
    this.uploadMessage = this.translateService.instant('DataUploadStatus', {
      value: this.translateService.instant('DataUploadStatusNotYet'),
    });
    this.sharedService.preloader(false);
  };
  /**
   * Loads the DICOM file into the cornerstone cache and prepares the data for upload to the server.
   * @returns {Promise<void>} A promise that resolves when the preview is loaded.
   */
  private loadPreview = async (): Promise<void> => {
    // Load DICOM File into connerstone cache.
    const { imageObject } = await this.dicomLoaderService.loadFromLocal(this.files, true);
    //Structure format for loaded dicom file for upload into Server
    this.files = [];
    if (imageObject && imageObject.length > 0) {
      const stuctureData = await this.preUploadService.structureData(imageObject);

      if (stuctureData) {
        this.previewitems = stuctureData.studyList;
      } else {
        this.sharedService.toastMessage(Severity.error, this.translateService.instant('LoadUploadFailure'));
      }
      this.disableResetButton = false;
    } else {
      this.sharedService.toastMessage(Severity.error, this.translateService.instant('UploadReadFileFailure'));
    }
  };
  /**
   * Called when a button is clicked. This is a callback for ProcessButtonType.
   *
   * @param event - The event that triggered this callback. It can be one of the following : ProcessButtonType. Upload -- Upload the file. ProcessButtonType. Reset
   * @param ProcessButtonType
   */
  public onButtonClick = async (event: ProcessButtonType) => {
    // Start upload or reset.
    switch (event) {
      case ProcessButtonType.Upload:
        await this.startUpload();
        break;
      case ProcessButtonType.Reset:
        // this.files = [];
        // this.previewitems = [];
        // this.disableResetButton = true;
        // this.uploadService.freeze();
        window.location.reload();
        break;
      default:
        break;
    }
  };

  /**
   * Starts the upload. This is called when the user presses the upload button.
   * @return Promise to be resolved when the upload is finished or rejected
   */
  public startUpload = async () => {
    // If there are no files in the list this method will raise an error message.
    if (this.previewitems.length === 0) {
      this.sharedService.toastMessage(Severity.error, this.translateService.instant('NotSelectUploadFile'));
      return;
    }

    //Start upload 1st study on list
    this.disableUploadButton = true;
    this.uploading = true;
    this.spy.scrollTo(this._state.uploadIndex.toString());
    //Trigger to preview component to start
    const component = this.previewChildern.filter((element, index) => index === this._state.uploadIndex);
    component[0].startUploadStudy(this.isOverrideData);
    this.uploadMessage = this.translateService.instant('DataUploadStatus', {
      value: this.translateService.instant('DataUploadStatusUploading', {
        data: this.translateService.instant('PreviewStudyUid', {
          patientId: component[0].patientId,
          patientName: component[0].patientName,
          uid: component[0].studyUid,
        }),
      }),
    });
  };

  /**
   * Uploads the next study based on the previous upload status.
   * @param {boolean} previousUploadStatus - The status of the previous upload.
   * @returns {Promise<void>} - A promise that resolves when the study is uploaded.
   */
  public async uploadNextStudy(previousUploadStatus) {
    //If current upload status is true(success without error). Start upload next study
    if (previousUploadStatus) {
      this._state.uploadIndex++;
      //Trigger to preview component to start
      const component = this.previewChildern.filter((element, index) => index === this._state.uploadIndex);
      if (component.length > 0) {
        component[0].startUploadStudy(this.isOverrideData);
        this.uploadMessage = this.translateService.instant('DataUploadStatus', {
          value: this.translateService.instant('DataUploadStatusUploading', {
            data: this.translateService.instant('PreviewStudyUid', {
              patientId: component[0].patientId,
              patientName: component[0].patientName,
              uid: component[0].studyUid,
            }),
          }),
        });
      } else {
        // All study has been uploaded to drive success without error
        this.resetProps();
        this.sharedService.toastMessage(Severity.info, this.translateService.instant('UploadSuccess'));
        this.uploadMessage = this.translateService.instant('DataUploadStatus', {
          value: this.translateService.instant('DataUploadStatusUploaded'),
        });
      }
    } else {
      // If uploaded has error in specific upload images. Stop upload instance and notify to user
      this.resetProps();
      this.sharedService.toastMessage(Severity.warn, this.translateService.instant('UploadFailure'));
      this.uploadMessage = this.translateService.instant('DataUploadStatus', {
        value: this.translateService.instant('DataUploadStatusUploaded'),
      });
    }
    // Scroll the review study
    this.spy.scrollTo(this._state.uploadIndex.toString());
  }

  /**
   * Resets the properties of the upload component.
   */
  private resetProps(): void {
    this.disableUploadButton = false;
    this.disableResetButton = false;
    this.uploading = false;
    this._state.uploadIndex = 0;
  }

  /**
   * Checks if the component can be deactivated.
   * @returns {boolean} - True if the component can be deactivated, false otherwise.
   */
  canDeactivate = async (): Promise<boolean> => {
    if (this.uploading) {
      const res = await this.sharedService.confirmDialog(Severity.warn, this.translateService.instant('UploadLeavePage'));
      if (res) {
        await this.uploadService.cancelUpload();
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.uploading) {
      const mess = this.translateService.instant('UploadLeavePage');
      return confirm(mess);
    } else {
      return;
    }
  }
}
