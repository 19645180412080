// @ts-ignore
import * as cornerstone from '@cornerstonejs/core';
import * as dicomParser from 'dicom-parser';
import * as wadoUriMetaDataProvider from '../helpers/wadouri-metadata-provider';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { Injectable } from '@angular/core';
import { Logger } from '@app/@shared';
import {
  prefetchMetadataInformation,
  prefetchImagesInformation,
  generateDownloadLink,
  generateDownloadLinks,
  prefetchSingleMetadataInformation,
  prefetchSingleMetadataInformationPromise,
  convertMultiframeImageId,
} from '../helpers/dicom-image';

import { IImage, IImagePrefetch } from '../models/IImage';
import { ImagePrefetch } from '../models/image-prefetch';
import { DicomTag } from '../models/dicom-tag';
import ptScalingMetaDataProvider from '../helpers/ptScalingMetaDataProvider';
import getPTImageIdInstanceMetadata from '../helpers/getPTImageIdInstanceMetadata';
import { InstanceMetadata, calculateSUVScalingFactors } from '@cornerstonejs/calculate-suv';
import { IImageUploadInfo } from '@app/upload/models/ISeriesUpload';
import { environment } from '@env/environment';
const { calibratedPixelSpacingMetadataProvider } = cornerstone.utilities;
const log = new Logger('dicom-service');

@Injectable({ providedIn: 'root' })
export class DicomLoaderService {
  constructor() {
    try {
      //register for development
      if (!environment.production) {
        window['cornerstone'] = cornerstone;
        window['cornerstoneDICOMImageLoader'] = cornerstoneDICOMImageLoader;
        window['dicomParser'] = dicomParser;
      }

      cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
      cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;
      const { preferSizeOverAccuracy, useNorm16Texture } = cornerstone.getConfiguration().rendering;
      cornerstoneDICOMImageLoader.configure({
        useWebWorkers: true,
        decodeConfig: {
          convertFloatPixelDataToInt: false,
          use16BitDataType: preferSizeOverAccuracy || useNorm16Texture,
        },
      });

      let maxWebWorkers = 1;

      if (navigator.hardwareConcurrency) {
        maxWebWorkers = Math.min(navigator.hardwareConcurrency, 4);
      }

      const config = {
        maxWebWorkers,
        startWebWorkersOnDemand: false,
        taskConfiguration: {
          decodeTask: {
            initializeCodecsOnStartup: false,
            strict: false,
          },
        },
      };
      cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);
      cornerstone.metaData.addProvider(wadoUriMetaDataProvider.get.bind(wadoUriMetaDataProvider), 100);
      cornerstone.metaData.addProvider(ptScalingMetaDataProvider.get.bind(ptScalingMetaDataProvider), 10000);
      cornerstone.metaData.addProvider(calibratedPixelSpacingMetadataProvider.get.bind(calibratedPixelSpacingMetadataProvider), 11000);
    } catch (error) {
      log.error(error);
    }
  }

  /**
   * Load image from local PC and prefetch DICOM metadata
   * @param files File browser object
   * @param storeLocalFile true: export Image object has beed prefetch, othewise return cache image id
   * @returns
   */
  public loadFromLocal = async (files: Array<File>, storeLocalFile: boolean = false): Promise<any> => {
    let imageIds = new Array();
    for (const file of files) {
      imageIds.push(cornerstoneDICOMImageLoader.wadouri.fileManager.add(file));
    }
    // Prefetch metadata information for a set of images. This is a low - level function
    if (storeLocalFile) {
      const imageObject = await prefetchImagesInformation(imageIds);
      debugger;
      return { imageObject };
    } else {
      imageIds = await prefetchMetadataInformation(imageIds);
      debugger;
      return { imageIds };
    }
  };

  /**
   * Loads DICOM images from the drive.
   * @param images - An array of IImagePrefetch objects representing the images to load.
   * @returns A Promise that resolves to an array of strings representing the image IDs.
   */
  public loadFromDrive = async (images: IImageUploadInfo[]): Promise<string[]> => {
    const token = gapi.auth.getToken().access_token;
    cornerstoneDICOMImageLoader.configure({
      beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
    });

    let imageIds = generateDownloadLinks(images, token !== undefined);
    imageIds = await prefetchMetadataInformation(imageIds);
    return imageIds;
  };

  /**
   * Loads a single DICOM image from the drive.
   * @param image - The image to be loaded.
   * @returns A promise that resolves to the image ID.
   */
  public loadSingleFromDrive = async (image: IImageUploadInfo): Promise<string> => {
    const token = gapi.auth.getToken().access_token;
    cornerstoneDICOMImageLoader.configure({
      beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
        xhr.setRequestHeader('Authorization', 'Bearer ' + gapi.auth.getToken().access_token);
      },
    });
    const imageId = generateDownloadLink(image, token !== undefined);
    await prefetchSingleMetadataInformation(imageId);
    return imageId;
  };

  /**
   * Loads a single DICOM image from the drive and return promise.
   * @param image - The image to be loaded.
   * @returns A promise.
   */
  public loadSingleFromDrivePromise = async (url: string): Promise<IImage> => {
    cornerstoneDICOMImageLoader.configure({
      beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
        xhr.setRequestHeader('Authorization', 'Bearer ' + gapi.auth.getToken().access_token);
      },
    });
    return prefetchSingleMetadataInformationPromise(url);
  };

  /**
   * Checks if the given image ID represents a multi-frame image.
   * @param imageId - The image ID to check.
   * @returns An array of strings representing the frames of the multi-frame image.
   */
  public checkMultiFrameByImage = (imageId: string): string[] => {
    const frame = convertMultiframeImageId(imageId);
    return frame;
  };

  /**
   * Retrieves the instance metadata for the given image IDs and performs additional processing.
   * @param imageIds - An array of image IDs.
   */
  public getPTInstanceMetadata = (imageIds: string[]) => {
    const InstanceMetadataArray: InstanceMetadata[] = [];
    imageIds.forEach((imageId) => {
      const instanceMetadata = getPTImageIdInstanceMetadata(imageId);
      // TODO: Temporary fix because static-wado is producing a string, not an array of values
      // (or maybe dcmjs isn't parsing it correctly?)
      // It's showing up like 'DECY\\ATTN\\SCAT\\DTIM\\RAN\\RADL\\DCAL\\SLSENS\\NORM'
      // but calculate-suv expects ['DECY', 'ATTN', ...]
      if (typeof instanceMetadata.CorrectedImage === 'string') {
        instanceMetadata.CorrectedImage = instanceMetadata.CorrectedImage.split('\\');
      }

      if (instanceMetadata) {
        InstanceMetadataArray.push(instanceMetadata);
      }
    });
    if (InstanceMetadataArray.length) {
      try {
        const suvScalingFactors = calculateSUVScalingFactors(InstanceMetadataArray);
        InstanceMetadataArray.forEach((instanceMetadata, index) => {
          ptScalingMetaDataProvider.addInstance(imageIds[index], suvScalingFactors[index]);
        });
      } catch (error) {
        log.error(error);
      }
    }
  };

  /**
   * Purges the cache for DICOM images and metadata.
   */
  public purceCache = (): void => {
    cornerstone.cache.purgeCache();
    cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.purge();
    cornerstoneDICOMImageLoader.wadors.metaDataManager.purge();
  };
}
