import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IMultiSelect, MODALITY_NAME, Threads } from '@app/@core';
import { Severity, SharedService } from '@app/@shared';
import { STUDY_DURATION, STUDY_DURATION_VALUE } from '@app/worklist/contants/search';
import { ISearch } from '@app/worklist/models/ISearch';
import { SearchWorklistService } from '@app/worklist/services/search.service';
import { clearSearchPayload, searchWorklist, updateSearchPayload } from '@app/worklist/store/worklist.actions';
import { worklistQuery } from '@app/worklist/store/worklist.selectors';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ButtonModule } from 'primeng/button';
import { Observable, Subscription } from 'rxjs';

/**
 * Represents the SearchComponent class.
 * This component is responsible for handling search functionality in the application.
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private searchService: SearchWorklistService,
    private store: Store,
  ) {}

  /**
   * Represents the patient ID.
   */
  protected patientId: any = '';

  /**
   * Represents the patient name.
   */
  protected patientName: any = '';

  /**
   * Represents the accession number.
   */
  protected accessionNumber: any = '';

  protected selectStudyDateOptions: any[] = [];

  protected studySearchValue: any = 'datetime';

  /**
   * Represents the study date from.
   */
  protected studyDateFrom: any = '';

  /**
   * Represents the study date to.
   */
  protected studyDateTo: any = '';

  /**
   *
   */
  protected selectStudyDuration: IMultiSelect;
  /**
   * Represents the study duration.
   */
  protected studyDuration: IMultiSelect[] = [];

  /**
   * Represents the selected modality.
   */
  protected selectedModality: IMultiSelect[] = [];

  /**
   * Represents the modalities.
   */
  protected modalities: IMultiSelect[] = [];

  private _subscribeSearch: Subscription;

  @ViewChild('searchBtn', { static: false }) searchButton!: any;
  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    Object.entries(MODALITY_NAME).forEach(([key, value]) => {
      this.modalities.push({ name: value, value: key });
    });
    Object.entries(STUDY_DURATION).forEach(([key, value]) => {
      this.studyDuration.push({ name: this.translateService.instant(value), value: key });
    });
    this.selectStudyDateOptions = [
      { label: this.translateService.instant('wlStudyDate'), value: 'datetime' },
      { label: this.translateService.instant('wlStudyDuration'), value: 'duration' },
    ];
    this.translateService.onLangChange.subscribe(() => {
      this.studyDuration = [];
      Object.entries(STUDY_DURATION).forEach(([key, value]) => {
        this.studyDuration.push({ name: this.translateService.instant(value), value: key });
      });
      this.selectStudyDateOptions = [];
      this.selectStudyDateOptions = [
        { label: this.translateService.instant('wlStudyDate'), value: 'datetime' },
        { label: this.translateService.instant('wlStudyDuration'), value: 'duration' },
      ];
    });
    this._subscribeSearch = this.searchService.searchStudy.subscribe((value) => this.bindSearchValue(value));
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Unsubscribes from the '_subscribeSearch' subscription.
   */
  ngOnDestroy(): void {
    this._subscribeSearch?.unsubscribe();
  }

  /**
   * Handles the search button event.
   * If any of the search fields are empty, the search will not be performed.
   * Otherwise, it raises the search study event with the provided search criteria.
   */
  public search = async () => {
    if (
      this.patientId === '' &&
      this.patientName === '' &&
      (this.studyDateFrom === null || this.studyDateFrom === '') &&
      (this.studyDateTo === null || this.studyDateTo === '') &&
      this.accessionNumber === '' &&
      (this.selectedModality === null || this.selectedModality.length === 0)
    ) {
      this.store.dispatch(clearSearchPayload());
      return;
    }
    // this.searchService.raiseSearchStudy({
    //   patientId: this.patientId || '',
    //   patientName: this.patientName || '',
    //   studyDateFrom: moment(this.studyDateFrom).toISOString() || '',
    //   studyDateTo: moment(this.studyDateTo).toISOString() || '',
    //   accessionNumber: this.accessionNumber || '',
    //   studyDuration: this.studyDuration || '',
    //   modalities: this.selectedModality.map((x) => x.value),
    // });
    this.sharedService.preloader(true);
    await Threads.Instance.sleep(100);
    this.store.dispatch(searchWorklist());
  };

  /**
   * Opens a confirm dialog when the user clicks on the reset button.
   * The dialog prompts the user to confirm the reset action.
   */
  public Clear = async () => {
    const result = await this.sharedService.confirmDialog(Severity.warn, this.translateService.instant('ClearSearchWorklist'));
    if (result) {
      // Clear all fields in search form
      this.patientId = '';
      this.patientName = '';
      this.studyDateFrom = '';
      this.studyDateTo = '';
      this.accessionNumber = '';
      this.selectStudyDuration = { name: '', value: '' };
      this.selectedModality = [];
      this.store.dispatch(clearSearchPayload());
    }
  };

  /**
   * Handles the blur event for the study duration input field.
   * Sets the `studyDateTo` and `studyDateFrom` properties to the current date and time.
   */
  protected onStudyDurationBlur() {
    if (this.selectStudyDuration === undefined || this.selectStudyDuration === null) {
      return;
    }
    this.studyDateTo = moment().toDate();
    switch (this.selectStudyDuration.value) {
      case STUDY_DURATION_VALUE.TODAY:
        this.studyDateFrom = moment().toDate();
        break;
      case STUDY_DURATION_VALUE.YESTERDAY:
        this.studyDateFrom = moment().subtract(1, 'days').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_7_DAYS:
        this.studyDateFrom = moment().subtract(7, 'days').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_1_MOUNTHS:
        this.studyDateFrom = moment().subtract(1, 'month').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_3_MONTHS:
        this.studyDateFrom = moment().subtract(3, 'month').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_6_MONTHS:
        this.studyDateFrom = moment().subtract(6, 'month').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_1_YEAR:
        this.studyDateFrom = moment().subtract(1, 'year').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_3_YEARS:
        this.studyDateFrom = moment().subtract(3, 'year').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_5_YEARS:
        this.studyDateFrom = moment().subtract(5, 'year').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_10_YEARS:
        this.studyDateFrom = moment().subtract(10, 'year').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_20_YEARS:
        this.studyDateFrom = moment().subtract(20, 'year').toDate();
        break;
      case STUDY_DURATION_VALUE.LAST_30_YEARS:
        this.studyDateFrom = moment().subtract(30, 'year').toDate();
        break;
      default:
        break;
    }
    this.store.dispatch(
      updateSearchPayload({
        payload: {
          studyDateFrom: moment(this.studyDateFrom).toISOString() || '',
          studyDateTo: moment(this.studyDateTo).toISOString() || '',
          studyDuration: this.selectStudyDuration.value || '',
        },
      }),
    );
  }

  /**
   * Event handler for the blur event on the study input field.
   * Resets the selected study duration to an empty state.
   *
   * @protected
   */
  protected onStudyFromBlur() {
    this.selectStudyDuration = { name: '', value: '' };
  }

  /**
   * Event handler for the blur event on the study input field.
   * Resets the selected study duration to an empty state.
   *
   * @protected
   * */
  protected onStudyToBlur() {
    this.selectStudyDuration = { name: '', value: '' };
  }

  /**
   * Handles the blur event on an input field.
   * Dispatches a search action to the store if the input value is not empty.
   *
   * @param key - The key associated with the input field.
   * @param value - The value of the input field.
   * @protected
   */
  protected onInputBlur(key: string, value: string) {
    this.store.dispatch(updateSearchPayload({ payload: { [key]: value } }));
  }

  /**
   * Handles the blur event on a calendar input field.
   * Dispatches a search action to the store if the input value is not empty.
   *
   * @param key - The key associated with the input field.
   * @param value - The value of the input field.
   * @protected
   */
  protected onCalendarBlur(key: string, value: any) {
    this.store.dispatch(updateSearchPayload({ payload: { [key]: moment(value).toISOString() || '' } }));
  }

  /**
   * Handles the blur event on a dropdown input field.
   * Dispatches a search action to the store if the input value is not empty.
   *
   * @param key - The key associated with the input field.
   * @param value - The value of the input field.
   * @protected
   */
  protected onDropdownChange(key: string, value: any) {
    this.store.dispatch(updateSearchPayload({ payload: { [key]: value.map((x: any) => x.value) } }));
  }

  /**
   * Binds the search values from the given payload to the component's properties.
   *
   * @param payload - The search payload containing the values to bind. If null, the function returns immediately.
   * @param payload.patientId - The ID of the patient.
   * @param payload.patientName - The name of the patient.
   * @param payload.accessionNumber - The accession number of the study.
   * @param payload.studyDuration - The duration of the study. If not empty or undefined, it sets the selected study duration and triggers the blur event.
   * @param payload.studyDateFrom - The start date of the study. Used if studyDuration is empty or undefined.
   * @param payload.studyDateTo - The end date of the study. Used if studyDuration is empty or undefined.
   * @param payload.modalities - The list of modalities to filter and select.
   */
  private bindSearchValue = async (payload: ISearch | null) => {
    if (payload === null) {
      return;
    }
    this.patientId = payload.patientId;
    this.patientName = payload.patientName;
    this.accessionNumber = payload.accessionNumber;
    if (payload.studyDuration !== '' && payload.studyDuration !== undefined) {
      this.selectStudyDuration = <IMultiSelect>this.studyDuration.find((x) => x.value === payload.studyDuration);
      this.onStudyDurationBlur();
    } else if (payload.studyDateFrom !== '' && payload.studyDateTo !== '' && payload.studyDateFrom !== undefined && payload.studyDateTo !== undefined) {
      this.studyDateFrom = moment(payload.studyDateFrom).toDate();
      this.studyDateTo = moment(payload.studyDateTo).toDate();
    }

    this.selectedModality = this.modalities.filter((x) => payload.modalities?.includes(x.value)) || '';
    this.store.dispatch(updateSearchPayload({ payload }));
    //TODO: Will implement if can fix issue not updating table realtime when across component
    // this.sharedService.preloader(true);
    // await Threads.Instance.sleep(1000);
    // this.searchService.raiseExecuteSearchStudy(payload);
  };
}
