import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { LoginMode, authQuery, firebaseAuthActions } from '@app/auth';
import { shellActions } from '@app/shell';
import { IFirebaseUser } from '@app/auth/models/IFirebaseUser';
import { IOnpremiseAPI } from '@app/auth/models/IUser';

import { ToolsService, BroadcastService } from '@app/viewer/services';
import {
  ViewerMode,
  SortCommand,
  Measure,
  Annotate,
  SortOrder,
  FUSION_LAYOUT,
  MPR_LAYOUT,
  CameraAction,
  BroadcastTool,
  ScrollMode,
  ScrollSync,
  AutoPlay,
  SyncScope,
  Tools,
} from '@app/viewer/contants';

import { ViewerLayoutQuery, layoutActions } from '@app/viewer/store/layout';
import { viewerMenuActions, ViewerMenuQuery } from '@app/viewer/store/menu';
import { viewerDriveActions, viewerGeneralActions, ViewerGeneralQuery } from '@app/viewer/store/general';
import { RouteHelpers, Severity, SharedService, WindowService } from '@app/@shared';
import { Router } from '@angular/router';
import { GoogleParams } from '@app/viewer/models';
import { APP_ROUTE_BASE } from '@app/@core';

@Component({
  selector: 'app-viewer-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class ViewerMenuComponent implements OnInit, AfterContentInit, OnDestroy {
  constructor(
    private store: Store,
    private toolsService: ToolsService,
    private translate: TranslateService,
    private broadcastService: BroadcastService,
    private cdr: ChangeDetectorRef,
    private route: Router,
    private window: WindowService,
    private sharedService: SharedService,
  ) {
    this._window = this.window.nativeWindow;
  }

  @ViewChild('viewerMenu') viewerMenuEl: ElementRef;
  protected menuItems: MenuItem[] = new Array();
  protected menuItemsMain: MenuItem[] = new Array();
  protected userInfo$: Observable<IFirebaseUser | IOnpremiseAPI | undefined>;
  protected metadataVisible$: Observable<boolean>;
  protected menuMode$: Observable<ViewerMode>;
  protected panelLayout$: Observable<string>;
  protected stackLayout$: Observable<string>;
  protected tileLayout$: Observable<string>;
  protected displayOverlay$: Observable<boolean>;
  protected displayReferenceLine$: Observable<boolean>;
  protected displayCrosshair$: Observable<boolean>;
  protected measure$: Observable<Measure>;
  protected annotate$: Observable<Annotate>;
  protected sortCommand$: Observable<SortCommand>;
  protected sortScope$: Observable<boolean>;
  protected sortDirection$: Observable<SortOrder>;
  protected toolActived$: Observable<Tools>;
  protected isScrollLoopActive$: Observable<boolean>;
  protected scrollMode$: Observable<ScrollMode>;
  protected scrollSync$: Observable<ScrollSync>;
  protected isWWWLActiveByROI$: Observable<boolean>;
  protected isWWWLInvest$: Observable<boolean>;
  protected isGuestMode$: Observable<boolean>;
  protected isShareMode$: Observable<boolean>;
  protected isOpenReport$: Observable<boolean>;
  protected fromDrive$: Observable<boolean>;

  protected syncScope$: Observable<SyncScope>;
  protected syncZoom$: Observable<boolean>;
  protected syncPan$: Observable<boolean>;
  protected syncRotate$: Observable<boolean>;
  protected syncWWWL$: Observable<boolean>;
  protected syncFilter$: Observable<boolean>;
  protected syncLUT$: Observable<boolean>;
  protected syncScroll$: Observable<boolean>;
  protected currentFilter$: Observable<string>;
  protected currentLut$: Observable<string>;
  protected mprLayout$: Observable<string>;
  protected mprDisplay2D$: Observable<boolean>;
  protected fusionLayout$: Observable<string>;
  protected fusionDisplay2D$: Observable<boolean>;
  protected isFullScreen$: Observable<boolean>;
  protected isMagnify$: Observable<boolean>;
  protected viewerMode$: Observable<ViewerMode>;
  private _MenuModeSubscription$: Subscription;
  private _translateSubscription$: Subscription;
  private _menuSubs: Subscription[] = [];
  private _currentMeasureIcon: string = 'icon icon-dwvicon_MeasureLength';
  private _currentAnnotateIcon: string = 'icon icon-Annotate';
  private _hiddenItems: number = 0;
  private _window: any;
  // @HostListener('window:resize')
  // onResize() {
  //   this.adjustMenu();
  // }

  //#region Angular lifecyle hook
  ngOnInit(): void {
    this._getDataFromStore();
    this._isBrowserFullScreen();
    this.menuMode$ = this.store.select(ViewerMenuQuery.selectActiveMode);
    this._MenuModeSubscription$ = this.menuMode$.subscribe((mode) => this._menuBuilder(mode));
    this._translateSubscription$ = this.translate.onLangChange.subscribe(() => this._menuBuilder());
  }

  ngAfterContentInit(): void {
    this.userInfo$ = this.store.select(authQuery.selectUserInfo);

    // setTimeout(() => {
    //   this.adjustMenu();
    // });
  }

  ngOnDestroy(): void {
    this._MenuModeSubscription$?.unsubscribe();
    this._menuSubs.map((sub) => sub?.unsubscribe());
    this._translateSubscription$.unsubscribe();
  }
  //#endregion

  /**
   * Adjusts the menu by rendering it and checking for overflow.
   * If the menu overflows, it hides items until it fits within the container.
   * @returns {Promise<void>} A promise that resolves when the menu adjustment is complete.
   */
  // protected async adjustMenu() {
  //   if (this.viewerMenuEl.nativeElement) {
  //     this.viewerMenuEl.nativeElement.style.overflow = 'hidden';
  //   }

  //   this._hiddenItems = 0;
  //   await this.renderMenu();
  //   await this.delay(10);

  //   if (this._isMenuOverflow()) {
  //     while (this._isMenuOverflow()) {
  //       this._hiddenItems++;
  //       await this.delay(10);
  //       await this.renderMenu();
  //     }
  //   }

  //   if (this.viewerMenuEl.nativeElement) {
  //     this.viewerMenuEl.nativeElement.style.overflow = '';
  //   }
  // }

  /**
   * Renders the menu based on the number of hidden items.
   * If there are no hidden items, the main menu items are set to the original menu items.
   * If there are hidden items, the main menu items are set to a modified version of the original menu items,
   * with a "More" item added at the end to represent the hidden items.
   *
   * @returns {Promise<void>} A promise that resolves when the menu rendering is complete.
   */
  protected async renderMenu() {
    if (this._hiddenItems <= 0) {
      this.menuItemsMain = this.menuItems;
    } else {
      this.menuItemsMain = [
        ...this.menuItems.slice(0, -this._hiddenItems),
        {
          label: this.translate.instant('More'),
          icon: 'pi pi-ellipsis-h',
          items: this.menuItems.slice(this.menuItems.length - this._hiddenItems),
        },
      ];
    }

    await this.delay(10);
    this.cdr.detectChanges();
  }

  /**
   * Delays the execution of code for a specified amount of time.
   * @param ms The number of milliseconds to delay the execution. Default is 0.
   * @returns A promise that resolves after the specified delay.
   */
  protected async delay(ms: number = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * Checks if the menu content overflows its container.
   * @returns {boolean} True if the menu content overflows, false otherwise.
   */
  private _isMenuOverflow() {
    const borderWidth = 1;
    const element = this.viewerMenuEl?.nativeElement;

    return element.scrollHeight - borderWidth > element.clientHeight;
  }

  //#region get menu state from store
  /**
   * Retrieves data from the store and subscribes to the corresponding selectors.
   * Whenever the state of any selector changes, it triggers the _menuBuilder method.
   */
  private _getDataFromStore = () => {
    this.isGuestMode$ = this.store.select(ViewerGeneralQuery.selectGuestMode);
    this.isShareMode$ = this.store.select(ViewerGeneralQuery.selectShareMode);
    this.fromDrive$ = this.store.select(ViewerGeneralQuery.selectFromDrive);
    this.viewerMode$ = this.store.select(ViewerGeneralQuery.selectViewerMode);
    this.metadataVisible$ = this.store.select(ViewerLayoutQuery.selectMetadataDisplay);
    this.panelLayout$ = this.store.select(ViewerMenuQuery.selectPanelLayout);
    this.stackLayout$ = this.store.select(ViewerMenuQuery.selectStackLayout);
    this.tileLayout$ = this.store.select(ViewerMenuQuery.selectStackTile);
    this.displayOverlay$ = this.store.select(ViewerMenuQuery.selectDisplayOverlay);
    this.displayReferenceLine$ = this.store.select(ViewerMenuQuery.selectDisplayReferenceLine);
    this.displayCrosshair$ = this.store.select(ViewerMenuQuery.selectDisplayCrosshair);
    this.measure$ = this.store.select(ViewerMenuQuery.selectMeasure);
    this.annotate$ = this.store.select(ViewerMenuQuery.selectAnnotate);
    this.sortCommand$ = this.store.select(ViewerMenuQuery.selectSortCommand);
    this.sortScope$ = this.store.select(ViewerMenuQuery.selectSortScope);
    this.sortDirection$ = this.store.select(ViewerMenuQuery.selectSortDirection);

    this.toolActived$ = this.store.select(ViewerMenuQuery.selectActiveTool);
    this.isScrollLoopActive$ = this.store.select(ViewerMenuQuery.selectIsScrollLoop);
    this.scrollMode$ = this.store.select(ViewerMenuQuery.selectScrollMode);
    this.scrollSync$ = this.store.select(ViewerMenuQuery.selectScrollSync);
    this.isWWWLActiveByROI$ = this.store.select(ViewerMenuQuery.selectActiveWWWLByROI);
    this.isWWWLInvest$ = this.store.select(ViewerMenuQuery.selectActiveWWWLInvert);

    this.syncScope$ = this.store.select(ViewerMenuQuery.selectSyncScope);
    this.syncZoom$ = this.store.select(ViewerMenuQuery.selectSyncZoom);
    this.syncPan$ = this.store.select(ViewerMenuQuery.selectSyncPan);
    this.syncRotate$ = this.store.select(ViewerMenuQuery.selectSyncRotate);
    this.syncWWWL$ = this.store.select(ViewerMenuQuery.selectSyncWWWL);
    this.syncFilter$ = this.store.select(ViewerMenuQuery.selectSyncFilter);
    this.syncLUT$ = this.store.select(ViewerMenuQuery.selectSyncLUT);
    this.syncScroll$ = this.store.select(ViewerMenuQuery.selectSyncScroll);
    this.currentFilter$ = this.store.select(ViewerMenuQuery.selectCurrentFilter);
    this.currentLut$ = this.store.select(ViewerMenuQuery.selectCurrentLut);
    this.mprLayout$ = this.store.select(ViewerMenuQuery.selectMprLayout);
    this.mprDisplay2D$ = this.store.select(ViewerMenuQuery.selectMprDisplay2D);
    this.fusionLayout$ = this.store.select(ViewerMenuQuery.selectFusionLayout);
    this.fusionDisplay2D$ = this.store.select(ViewerMenuQuery.selectFusionDisplay2D);
    this.isFullScreen$ = this.store.select(ViewerMenuQuery.selectIsFullScreen);
    this.isMagnify$ = this.store.select(ViewerMenuQuery.selectMagnify);
    this.isOpenReport$ = this.store.select(ViewerMenuQuery.selectReportOpen);

    this._menuSubs.push(this.metadataVisible$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.panelLayout$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.stackLayout$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.tileLayout$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.displayOverlay$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.displayReferenceLine$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.displayCrosshair$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.measure$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.annotate$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.scrollMode$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.scrollSync$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isScrollLoopActive$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.sortCommand$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.sortScope$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.sortDirection$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.toolActived$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isWWWLActiveByROI$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isWWWLInvest$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.syncScope$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncZoom$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncPan$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncRotate$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncWWWL$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncFilter$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncLUT$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.syncScroll$.subscribe((state) => this._menuBuilder()));

    this._menuSubs.push(this.currentFilter$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.mprLayout$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.mprDisplay2D$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.fusionLayout$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.fusionDisplay2D$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isFullScreen$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isMagnify$.subscribe((state) => this._menuBuilder()));
    this._menuSubs.push(this.isOpenReport$.subscribe((state) => this._menuBuilder()));
  };

  //#endregion

  //#region  Subscriptions observable menu items state form store return css class.

  /**
   * Checks if the menu is active based on the provided subscription and condition.
   * @param subscription - The subscription to check the state from.
   * @param condition - The optional condition to compare the state against.
   * @returns The CSS class name to apply if the menu is active, or an empty string if not.
   */
  private _isMenuActive = async (subscription, condition?) => {
    const state = await firstValueFrom(subscription);
    if (condition !== undefined) {
      return state === condition ? 'menu-item--activated' : '';
    }
    return state ? 'menu-item--activated' : '';
  };

  /**
   * Checks if the menu is selected based on the given condition.
   * @param subscription - The subscription to retrieve the state from.
   * @param condition - The condition to compare the state against.
   * @returns The CSS class name for the menu icon based on the state.
   */
  private _isMenuSelected = async (subscription, condition) => {
    const state = await firstValueFrom(subscription);
    return state === condition ? 'icon icon-Selected' : 'icon icon-Unselect';
  };

  /**
   * Determines the CSS class for the menu icon based on the state of the subscription.
   * @param subscription - The subscription to check the state of.
   * @returns The CSS class for the menu icon ('icon icon-Checked' or 'icon icon-Unchecked').
   */
  private _isMenuChecked = async (subscription) => {
    const state = await firstValueFrom(subscription);
    return state ? 'icon icon-Checked' : 'icon icon-Unchecked';
  };

  /**
   * Determines the visibility of the metadata.
   * @returns A CSS class name indicating the visibility status.
   */
  private _isMetadataVisible = async () => {
    const status = await firstValueFrom(this.metadataVisible$);
    return !status ? 'menu-item--activated' : '';
  };

  /**
   * Retrieves the current measure and sets the corresponding icon.
   * @returns An object containing the icon and the current measure state.
   */
  private _getCurrentMeasure = async () => {
    const state = await firstValueFrom(this.measure$);
    switch (state) {
      case Measure.Length:
        this._currentMeasureIcon = 'icon icon-dwvicon_MeasureLength';
        break;
      case Measure.Angle:
        this._currentMeasureIcon = 'icon icon-Angle';
        break;
      // case Measure.Polygon:
      //   this._currentMeasureIcon = 'icon icon-Polyline';
      //   break;
      case Measure.CobbAngle:
        this._currentMeasureIcon = 'icon icon-CobbAngle';
        break;
      // case Measure.IntersectionAngle:
      //   this._currentMeasureIcon = 'icon icon-NorbergAngle';
      //   break;
      case Measure.ROIEllipse:
        this._currentMeasureIcon = 'icon icon-AreaEclipse';
        break;
      case Measure.ROIRectangle:
        this._currentMeasureIcon = 'icon icon-AreaRectangle';
        break;
      // case Measure.ROIPolygon:
      //   this._currentMeasureIcon = 'icon icon-AreaPolygon';
      //   break;
      case Measure.Intensity:
        this._currentMeasureIcon = 'icon icon-Intensity';
        break;
      // case Measure.CTR:
      //   this._currentMeasureIcon = 'icon icon-CTR';
      //   break;
      case Measure.Bidirectional:
        this._currentMeasureIcon = 'icon icon-Bidirectional';
        break;
      case Measure.CircleROI:
        this._currentMeasureIcon = 'icon icon-AreaCircle';
        break;
      case Measure.PlannarFreehandROI:
        this._currentMeasureIcon = 'icon icon-PlanarFreehand';
        break;
      default:
        //Not change icon if current mesaure is none
        break;
    }
    return { icon: this._currentMeasureIcon, currentMeasure: state };
  };

  /**
   * Checks if the measure state is active and returns the corresponding CSS class.
   * @returns The CSS class 'menu-item--activated' if the measure state is not 'Measure.None', otherwise an empty string.
   */
  private _isActiveMesure = async () => {
    const state = await firstValueFrom(this.measure$);
    return state !== Measure.None ? 'menu-item--activated' : '';
  };

  /**
   * Retrieves the current annotation state and returns an object containing the icon and current annotation type.
   * @returns {Promise<{ icon: string, currentAnnotate: Annotate }>} The current annotation state.
   */
  private _getCurrentAnnotate = async () => {
    const state = await firstValueFrom(this.annotate$);
    switch (state) {
      case Annotate.Arrow:
        this._currentAnnotateIcon = 'icon icon-Arrow';
        break;
      case Annotate.Ellipse:
        this._currentAnnotateIcon = 'icon icon-AreaEclipse';

        break;
      case Annotate.Rectangle:
        this._currentAnnotateIcon = 'icon icon-AreaRectangle';
        break;
      case Annotate.Text:
        this._currentAnnotateIcon = 'icon icon-Annotate';
        break;

      default:
        break;
    }
    return {
      icon: this._currentAnnotateIcon,
      currentAnnotate: state,
    };
  };

  /**
   * Checks if the annotate state is active and returns the corresponding CSS class.
   * @returns The CSS class 'menu-item--activated' if the annotate state is not 'Annotate.None', otherwise an empty string.
   */
  private _isActiveAnnotate = async () => {
    const state = await firstValueFrom(this.annotate$);
    return state !== Annotate.None ? 'menu-item--activated' : '';
  };

  /**
   * Gets the sort direction based on the current state.
   * @returns The CSS class for the sort direction icon.
   */
  private _getSortDirection = async () => {
    const state = await firstValueFrom(this.sortDirection$);
    return state === SortOrder.Ascending ? 'icon icon-SortAscending' : 'icon icon-SortDescending';
  };

  //#endregion

  /**
   * Builds the menu based on the viewer mode.
   * @param menuMode The menu mode {@link ViewerMode}
   */
  private _menuBuilder = async (menuMode?: ViewerMode) => {
    if (menuMode === undefined && this.menuMode$ === undefined) {
      return;
    }
    const guestMode = await firstValueFrom(this.isGuestMode$);
    let mode: ViewerMode;
    //From viewer mode subscription
    if (menuMode) {
      mode = menuMode;
    } else {
      //Update from store each menu
      mode = await firstValueFrom(this.menuMode$);
    }
    //Always have menu change visible metadata area
    this.menuItems = [
      {
        label: this.translate.instant('Patient'),
        icon: 'icon icon-Patient',
        id: 'menu-metadata',
        styleClass: await this._isMetadataVisible(),
        command: () => {
          this.store.dispatch(layoutActions.changeMetadataDisplay());
        },
      },
    ];

    //Layout change Area && Display Area
    switch (mode) {
      case ViewerMode.Stack2D:
        this.menuItems = this.menuItems.concat(await this._layoutMenu2D());
        this.menuItems = this.menuItems.concat(await this._displayMenu2D());
        break;
      case ViewerMode.MPR:
        this.menuItems = this.menuItems.concat(await this._layoutMenuMPR());
        this.menuItems = this.menuItems.concat(await this._displayVolume());
        break;
      default:
        //ViewerMode.Fusion
        this.menuItems = this.menuItems.concat(await this._layoutMenuFusion());
        this.menuItems = this.menuItems.concat(await this._displayVolume());
        break;
    }

    //Measure Area
    if (mode === ViewerMode.Stack2D) {
      this.menuItems = this.menuItems.concat(await this._measureMenu());
      this.menuItems = this.menuItems.concat(await this._annotationMenu());
      this.menuItems = this.menuItems.concat(await this._scrollMenu2D());
      this.menuItems = this.menuItems.concat(await this._zoomMenu());
      this.menuItems = this.menuItems.concat(await this._wwwlMenu());
      this.menuItems = this.menuItems.concat(await this._panMenu());
      this.menuItems = this.menuItems.concat(await this._rotateMenu());
      this.menuItems = this.menuItems.concat(await this._syncMenu());
      this.menuItems = this.menuItems.concat(await this._selectMenu());
      this.menuItems = this.menuItems.concat(await this._toolsMenu2D());
    } else {
      this.menuItems = this.menuItems.concat(await this._measureVolumeMenu());
      this.menuItems = this.menuItems.concat(await this._annotationVolumeMenu());
      this.menuItems = this.menuItems.concat(await this._zoomVolumeMenu());
      this.menuItems = this.menuItems.concat(await this._wwwlVolumeMenu());
      this.menuItems = this.menuItems.concat(await this._panVolumeMenu());
      this.menuItems = this.menuItems.concat(await this._selectVolumeMenu());
      //this.menuItems = this.menuItems.concat(await this._toolsMenu3D());
    }

    if (mode === ViewerMode.Stack2D) {
      this.menuItems = this.menuItems.concat(await this._mpr3dMenu());
      this.menuItems = this.menuItems.concat(await this._fusionMenu());
    } else if (mode === ViewerMode.MPR) {
      this.menuItems = this.menuItems.concat(await this._mpr3dOnModeMenu());
    } else {
      this.menuItems = this.menuItems.concat(await this._fusionOnModeMenu());
    }
    //Report Area
    if (!guestMode) {
      this.menuItems = this.menuItems.concat(await this._reportMenu());
    }
    //FullScreen
    this.menuItems = this.menuItems.concat(await this._fullScreen());
    this.menuItemsMain = this.menuItems;
  };

  //#region Menu builder

  /**
   * Generates the layout menu for the 2D viewer.
   * @returns {Promise<any[]>} The layout menu items.
   */
  private _layoutMenu2D = async () => {
    return [
      {
        label: this.translate.instant('Layout'),
        icon: 'icon icon-dwvicon_ChangePanel',
        items: [
          {
            label: this.translate.instant('Stack'),
            icon: 'icon icon-dwvicon_ChangePanel',
            id: 'menu-stack',
            items: await this._stackMenu(),
          },
          {
            label: this.translate.instant('Tile'),
            icon: 'icon icon-Panel2x3',
            id: 'menu-tile',
            items: await this._tileMenu(),
          },
        ],
      },
    ];
  };

  //Remember: Remove change panel layout on 2D mode
  // private _panelMenu = async () => {
  //   return [
  //     {
  //       label: this.translate.instant('Panel1x1'),
  //       icon: 'icon icon-dwvicon_ScopePanel',
  //       styleClass: await this._isMenuActive(this.panelLayout$, '1x1'),
  //       command: () => {
  //         this.store.dispatch(layoutActions.changePanelLayout({ panelLayout: '1x1' }));
  //       },
  //       id: 'menu-panel-1x1',
  //     },
  //     {
  //       label: this.translate.instant('Panel1x2'),
  //       icon: 'icon icon-dwvicon_ScopePanels',
  //       styleClass: await this._isMenuActive(this.panelLayout$, '1x2'),
  //       command: () => {
  //         this.store.dispatch(layoutActions.changePanelLayout({ panelLayout: '1x2' }));
  //       },
  //       id: 'menu-panel-1x2',
  //     },
  //     {
  //       label: this.translate.instant('Panel1x3'),
  //       icon: 'icon icon-dwvicon_ScopeStudy',
  //       styleClass: await this._isMenuActive(this.panelLayout$, '1x3'),
  //       command: () => {
  //         this.store.dispatch(layoutActions.changePanelLayout({ panelLayout: '1x3' }));
  //       },
  //       id: 'menu-panel-1x2',
  //     },
  //   ];
  // };

  /**
   * Returns an array of menu items for the stack layout.
   * Each menu item contains a label, icon, style class, command, and id.
   * The style class is determined based on the current stack layout.
   * The command dispatches an action to change the stack layout.
   * @returns {Promise<Array<{ label: string, icon: string, styleClass: string, command: () => void, id: string }>>} The array of menu items.
   */
  private _stackMenu = async () => {
    return [
      {
        label: this.translate.instant('Stack1x1'),
        icon: 'icon icon-dwvicon_ScopePanel',
        styleClass: await this._isMenuActive(this.stackLayout$, '1x1'),
        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '1x1' }));
        },
        id: 'menu-stack-1x1',
      },
      {
        label: this.translate.instant('Stack1x2'),
        icon: 'icon icon-dwvicon_ScopePanels',
        styleClass: await this._isMenuActive(this.stackLayout$, '1x2'),
        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '1x2' }));
        },
        id: 'menu-stack-1x2',
      },
      {
        label: this.translate.instant('Stack1x3'),
        icon: 'icon icon-dwvicon_ScopeStudy',
        styleClass: await this._isMenuActive(this.stackLayout$, '1x3'),

        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '1x3' }));
        },
        id: 'menu-stack-1x3',
      },
      {
        label: this.translate.instant('Stack2x1'),
        icon: 'icon icon-Panel2x1',
        styleClass: await this._isMenuActive(this.stackLayout$, '2x1'),
        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '2x1' }));
        },
        id: 'menu-stack-2x1',
      },
      {
        label: this.translate.instant('Stack2x2'),
        icon: 'icon icon-dwvicon_ChangePanel',
        styleClass: await this._isMenuActive(this.stackLayout$, '2x2'),
        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '2x2' }));
        },
        id: 'menu-stack-2x2',
      },
      {
        label: this.translate.instant('Stack2x3'),
        icon: 'icon icon-Panel2x3',
        styleClass: await this._isMenuActive(this.stackLayout$, '2x3'),
        command: () => {
          this.store.dispatch(layoutActions.changeStackLayout({ stackLayout: '2x3' }));
        },
        id: 'menu-stack-2x3',
      },
    ];
  };

  /**
   * Returns an array of menu items for tile layout.
   * Each menu item contains a label, icon, style class, command, and id.
   * The label is translated using the `translate` service.
   * The style class is determined based on the current tile layout.
   * The command dispatches a layout change action to the store.
   * The id is used to identify the menu item.
   *
   * @returns {Promise<Array<{ label: string, icon: string, styleClass: string, command: () => void, id: string }>>} The array of menu items.
   */
  private _tileMenu = async () => {
    return [
      {
        label: this.translate.instant('Tile1x1'),
        icon: 'icon icon-dwvicon_ScopePanel',
        styleClass: await this._isMenuActive(this.tileLayout$, '1x1'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '1x1' }));
        },
        id: 'menu-tile-1x1',
      },
      {
        label: this.translate.instant('Tile1x2'),
        icon: 'icon icon-dwvicon_ScopePanels',
        styleClass: await this._isMenuActive(this.tileLayout$, '1x2'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '1x2' }));
        },
        id: 'menu-tile-1x2',
      },
      {
        label: this.translate.instant('Tile1x3'),
        icon: 'icon icon-dwvicon_ScopeStudy',
        styleClass: await this._isMenuActive(this.tileLayout$, '1x3'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '1x3' }));
        },
        id: 'menu-tile-1x3',
      },
      {
        label: this.translate.instant('Tile2x1'),
        icon: 'icon icon-Panel2x1',
        styleClass: await this._isMenuActive(this.tileLayout$, '2x1'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '2x1' }));
        },
        id: 'menu-tile-2x1',
      },
      {
        label: this.translate.instant('Tile2x2'),
        icon: 'icon icon-dwvicon_ChangePanel',
        styleClass: await this._isMenuActive(this.tileLayout$, '2x2'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '2x2' }));
        },
        id: 'menu-tile-2x2',
      },
      {
        label: this.translate.instant('Tile2x3'),
        icon: 'icon icon-Panel2x3',
        styleClass: await this._isMenuActive(this.tileLayout$, '2x3'),
        command: () => {
          this.store.dispatch(layoutActions.changeTileLayout({ tileLayout: '2x3' }));
        },
        id: 'menu-tile-2x3',
      },
    ];
  };

  /**
   * Generates the layout menu for MPR (Multi-Planar Reconstruction).
   * @returns {Promise<object[]>} An array of menu items for the MPR layout.
   */
  private _layoutMenuMPR = async () => {
    return [
      {
        label: this.translate.instant('Layout'),
        icon: 'icon icon-dwvicon_ChangePanel',
        items: [
          {
            label: this.translate.instant('MPR(3V)'),
            icon: await this._isMenuSelected(this.mprLayout$, MPR_LAYOUT['3V0H']),
            id: 'menu-mpr-3v',
            styleClass: await this._isMenuActive(this.mprLayout$, MPR_LAYOUT['3V0H']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onMPRLayoutChange({ layout: MPR_LAYOUT['3V0H'] }));
            },
          },
          {
            label: this.translate.instant('MPR(2H1V)'),
            icon: await this._isMenuSelected(this.mprLayout$, MPR_LAYOUT['2H1V']),
            id: 'menu-mpr-2h1v',
            styleClass: await await this._isMenuActive(this.mprLayout$, MPR_LAYOUT['2H1V']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onMPRLayoutChange({ layout: MPR_LAYOUT['2H1V'] }));
            },
          },

          {
            label: this.translate.instant('MPR(3H3D)'),
            icon: await this._isMenuSelected(this.mprLayout$, MPR_LAYOUT['3H1D']),
            id: 'menu-mpr-3h3d',
            styleClass: await await this._isMenuActive(this.mprLayout$, MPR_LAYOUT['3H1D']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onMPRLayoutChange({ layout: MPR_LAYOUT['3H1D'] }));
            },
          },
          {
            label: this.translate.instant('MPR(3D)'),
            icon: await this._isMenuSelected(this.mprLayout$, MPR_LAYOUT['1D0D']),
            id: 'menu-mpr-3h3d',
            styleClass: await this._isMenuActive(this.mprLayout$, MPR_LAYOUT['1D0D']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onMPRLayoutChange({ layout: MPR_LAYOUT['1D0D'] }));
            },
          },
          // {
          //   label: this.translate.instant('MPR(2D)'),
          //   icon: await this._isMenuChecked(this.mprDisplay2D$),
          //   id: 'menu-mpr-2d',
          //   styleClass: await this._isMenuActive(this.mprDisplay2D$),
          //   command: () => {
          //     this.store.dispatch(viewerMenuActions.onMPRVisible2DChange());
          //   },
          // },
        ],
      },
    ];
  };

  /**
   * Generates the menu items for the Fusion layout in the menu component.
   * @returns {Promise<Array<any>>} The array of menu items for the Fusion layout.
   */
  private _layoutMenuFusion = async () => {
    return [
      {
        label: this.translate.instant('Layout'),
        icon: 'icon icon-dwvicon_ChangePanel',
        items: [
          {
            label: this.translate.instant('Fusion(3H3V1V)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['3H3V1V']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['3H3V1V']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['3H3V1V'] }));
            },
          },
          {
            label: this.translate.instant('Fusion(3V)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['3V0H']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['3V0H']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['3V0H'] }));
            },
          },
          {
            label: this.translate.instant('Fusion(2V2H)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['2V2H']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['2V2H']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['2V2H'] }));
            },
          },
          {
            label: this.translate.instant('Fusion(2H3V)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['2H3V']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['2H3V']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['2H3V'] }));
            },
          },
          {
            label: this.translate.instant('Fusion(2H2V)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['2H2V']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['2H2V']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['2H2V'] }));
            },
          },
          {
            label: this.translate.instant('Fusion(4V)'),
            icon: await this._isMenuSelected(this.fusionLayout$, FUSION_LAYOUT['4V0H']),
            styleClass: await this._isMenuActive(this.fusionLayout$, FUSION_LAYOUT['4V0H']),
            command: () => {
              this.store.dispatch(viewerMenuActions.onFusionLayoutChange({ layout: FUSION_LAYOUT['4V0H'] }));
            },
          },
          // {
          //   label: this.translate.instant('Fusion(2D)'),
          //   icon: await this._isMenuChecked(this.fusionDisplay2D$),
          //   id: 'menu-mpr-2d',
          //   styleClass: await this._isMenuActive(this.fusionDisplay2D$),
          //   command: () => {
          //     this.store.dispatch(viewerMenuActions.onFusionVisible2DChange());
          //   },
          // },
        ],
      },
    ];
  };

  /**
   * Retrieves the menu items for the 2D display menu.
   * @returns {Promise<Array<object>>} The array of menu items.
   */
  private _displayMenu2D = async () => {
    return [
      {
        label: this.translate.instant('Display'),
        icon: 'icon icon-Display',
        items: [
          {
            label: this.translate.instant('PreviousSeriesPage'),
            icon: 'icon icon-Page-Previous',
            command: () => {
              this.store.dispatch(viewerMenuActions.prevSeries());
            },
          },
          {
            label: this.translate.instant('NextSeriesPage'),
            icon: 'icon icon-Page-Next',
            command: () => {
              this.store.dispatch(viewerMenuActions.nextSeries());
            },
          },
          {
            label: this.translate.instant('DisplayOverlay'),
            icon: 'icon icon-dwvicon_Overlay',
            id: 'menu-display-overlay',
            styleClass: await this._isMenuActive(this.displayOverlay$),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeDisplayOverlay());
            },
          },
          {
            label: this.translate.instant('DisplayReferenceLine'),
            icon: 'icon icon-DisplayRefer',
            styleClass: await await this._isMenuActive(this.displayReferenceLine$),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeDisplayReferenceLine());
            },
            id: 'menu-display-reference-line',
          },
          {
            label: this.translate.instant('Sort'),
            icon: await this._getSortDirection(),
            id: 'menu-sort',
            items: await this._sortMenu(),
          },
          {
            label: this.translate.instant('CloseStack'),
            icon: 'icon icon-DisplayClosePanel',
            command: () => {
              this.store.dispatch(viewerMenuActions.closeSeries());
            },
          },
        ],
      },
    ];
  };

  /**
   * Retrieves the display volume configuration.
   * @returns An array of display volume configuration objects.
   */
  private _displayVolume = async () => {
    return [
      {
        label: this.translate.instant('Display'),
        icon: 'icon icon-Display',
        items: [
          {
            label: this.translate.instant('DisplayOverlay'),
            icon: 'icon icon-dwvicon_Overlay',
            id: 'menu-display-overlay',
            styleClass: await await this._isMenuActive(this.displayOverlay$),
          },
          {
            label: this.translate.instant('DisplayCrossHair'),
            icon: 'icon icon-Display-CrossHair',
            styleClass: await this._isMenuActive(this.displayCrosshair$),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeDisplayCrosshair());
            },
            id: 'menu-display-crosshair',
          },
          // {
          //   label: this.translate.instant('Filter'),
          //   icon: 'icon icon-Filter',
          //   id: 'menu-filter',
          //   items: await this._filterMenu(),
          // },
          // {
          //   label: this.translate.instant('LUT'),
          //   icon: 'icon icon-LUT',
          //   //TODO reference LUT from cornerstone
          //   items: await this._lutMenu(),
          // },
          {
            label: this.translate.instant('ResetCameraVolume'),
            icon: 'icon icon-Reset',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.Reset);
            },
            id: 'menu-display-reset-volume',
          },
        ],
      },
    ];
  };

  /**
   * Retrieves the measure menu items.
   * @returns {Promise<Array<any>>} The measure menu items.
   */
  private _measureMenu = async () => {
    return [
      {
        label: this.translate.instant('Measure'),
        icon: (await this._getCurrentMeasure()).icon,
        id: 'menu-measure',
        styleClass: await this._isActiveMesure(),
        items: [
          {
            label: this.translate.instant('MeasureLength'),
            icon: 'icon icon-dwvicon_MeasureLength',
            id: 'menu-measure-length',
            styleClass: await this._isMenuActive(this.measure$, Measure.Length),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.Length })),
          },
          // {
          //   label: this.translate.instant('MeasurePolygon'),
          //   icon: 'icon icon-Polyline',
          //   id: 'menu-measure-polygon',
          //   styleClass: await this._isMenuActive(this.measure$, Measure.Polygon),
          // },
          {
            label: this.translate.instant('MeasureAngle'),
            icon: 'icon icon-Angle',
            id: 'menu-measure-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.Angle),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.Angle })),
          },
          {
            label: this.translate.instant('MeasureCobbAngle'),
            icon: 'icon icon-CobbAngle',
            id: 'menu-measure-cobb-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.CobbAngle),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.CobbAngle })),
          },
          {
            label: this.translate.instant('MeasureBidirectional'),
            icon: 'icon icon-Bidirectional',
            id: 'menu-measure-cobb-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.Bidirectional),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.Bidirectional })),
          },
          // {
          //   label: this.translate.instant('MeasureIntersectionAngle'),
          //   icon: 'icon icon-NorbergAngle',
          //   id: 'menu-measure-intersection-angle',
          //   styleClass: await await this._isMenuActive(this.measure$, Measure.IntersectionAngle),
          // },
          {
            label: this.translate.instant('MeasureROI(Ellipsis)'),
            icon: 'icon icon-AreaEclipse',
            id: 'menu-measure-roi-ellipsis',
            styleClass: await this._isMenuActive(this.measure$, Measure.ROIEllipse),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.ROIEllipse })),
          },
          {
            label: this.translate.instant('MeasureROI(Rectangle)'),
            icon: 'icon icon-AreaRectangle',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.ROIRectangle),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.ROIRectangle })),
          },
          {
            label: this.translate.instant('MeasureROI(Circle)'),
            icon: 'icon icon-AreaCircle',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.CircleROI),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.CircleROI })),
          },
          {
            label: this.translate.instant('MeasureROI(PlanarFreehand)'),
            icon: 'icon icon-PlanarFreehand',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.PlannarFreehandROI),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.PlannarFreehandROI })),
          },
          // {
          //   label: this.translate.instant('MeasureROI(Polygon)'),
          //   icon: 'icon icon-AreaPolygon',
          //   id: 'menu-measure-roi-polygon',
          //   styleClass: await await this._isMenuActive(this.measure$, Measure.ROIPolygon),
          // },
          {
            label: this.translate.instant('MeasureIntensity'),
            icon: 'icon icon-Intensity',
            id: 'menu-measure-intensity',
            styleClass: await this._isMenuActive(this.measure$, Measure.Intensity),
            command: () => this.store.dispatch(viewerMenuActions.onMeasure({ name: Measure.Intensity })),
          },
          // {
          //   label: this.translate.instant('MeasureCTR'),
          //   icon: 'icon icon-CTR',
          //   id: 'menu-measure-ctr',
          //   styleClass:  await this._isMenuActive(this.measure$, Measure.CTR),
          // },
        ],
      },
    ];
  };

  /**
   * Retrieves the menu items for volume measurement.
   * @returns An array of menu items for volume measurement.
   */
  private _measureVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('Measure'),
        icon: (await this._getCurrentMeasure()).icon,
        id: 'menu-measure',
        styleClass: await this._isActiveMesure(),
        items: [
          {
            label: this.translate.instant('MeasureLength'),
            icon: 'icon icon-dwvicon_MeasureLength',
            id: 'menu-measure-length',
            styleClass: await this._isMenuActive(this.measure$, Measure.Length),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.Length })),
          },
          // {
          //   label: this.translate.instant('MeasurePolygon'),
          //   icon: 'icon icon-Polyline',
          //   id: 'menu-measure-polygon',
          //   styleClass: await this._isMenuActive(this.measure$, Measure.Polygon),
          // },
          {
            label: this.translate.instant('MeasureAngle'),
            icon: 'icon icon-Angle',
            id: 'menu-measure-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.Angle),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.Angle })),
          },
          {
            label: this.translate.instant('MeasureCobbAngle'),
            icon: 'icon icon-CobbAngle',
            id: 'menu-measure-cobb-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.CobbAngle),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.CobbAngle })),
          },
          {
            label: this.translate.instant('MeasureBidirectional'),
            icon: 'icon icon-Bidirectional',
            id: 'menu-measure-cobb-angle',
            styleClass: await this._isMenuActive(this.measure$, Measure.Bidirectional),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.Bidirectional })),
          },
          // {
          //   label: this.translate.instant('MeasureIntersectionAngle'),
          //   icon: 'icon icon-NorbergAngle',
          //   id: 'menu-measure-intersection-angle',
          //   styleClass: await await this._isMenuActive(this.measure$, Measure.IntersectionAngle),
          // },
          {
            label: this.translate.instant('MeasureROI(Ellipsis)'),
            icon: 'icon icon-AreaEclipse',
            id: 'menu-measure-roi-ellipsis',
            styleClass: await this._isMenuActive(this.measure$, Measure.ROIEllipse),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.ROIEllipse })),
          },
          {
            label: this.translate.instant('MeasureROI(Rectangle)'),
            icon: 'icon icon-AreaRectangle',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.ROIRectangle),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.ROIRectangle })),
          },
          {
            label: this.translate.instant('MeasureROI(Circle)'),
            icon: 'icon icon-AreaCircle',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.CircleROI),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.CircleROI })),
          },
          {
            label: this.translate.instant('MeasureROI(PlanarFreehand)'),
            icon: 'icon icon-PlanarFreehand',
            id: 'menu-measure-roi-rectangle',
            styleClass: await this._isMenuActive(this.measure$, Measure.PlannarFreehandROI),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.PlannarFreehandROI })),
          },
          // {
          //   label: this.translate.instant('MeasureROI(Polygon)'),
          //   icon: 'icon icon-AreaPolygon',
          //   id: 'menu-measure-roi-polygon',
          //   styleClass: await await this._isMenuActive(this.measure$, Measure.ROIPolygon),
          // },
          {
            label: this.translate.instant('MeasureIntensity'),
            icon: 'icon icon-Intensity',
            id: 'menu-measure-intensity',
            styleClass: await this._isMenuActive(this.measure$, Measure.Intensity),
            command: () => this.store.dispatch(viewerMenuActions.onVolumeMeasure({ name: Measure.Intensity })),
          },
          // {
          //   label: this.translate.instant('MeasureCTR'),
          //   icon: 'icon icon-CTR',
          //   id: 'menu-measure-ctr',
          //   styleClass:  await this._isMenuActive(this.measure$, Measure.CTR),
          // },
        ],
      },
    ];
  };

  /**
   * Retrieves the annotation menu items.
   * @returns {Promise<Array<Object>>} The array of annotation menu items.
   */
  private _annotationMenu = async () => {
    return [
      {
        label: this.translate.instant('Annotate'),
        icon: (await this._getCurrentAnnotate()).icon,
        id: 'menu-annotate',
        styleClass: await this._isActiveAnnotate(),
        items: [
          // {
          //   label: this.translate.instant('AnnotateText'),
          //   icon: 'icon icon-Annotate',
          //   id: 'menu-annotate-textbox',
          //   styleClass: await this._isMenuActive(this.annotate$, Annotate.Text),
          // },
          {
            label: this.translate.instant('AnnotateArrow'),
            icon: 'icon icon-Arrow',
            id: 'menu-annotate-arrow',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Arrow),
            command: () => {
              this.store.dispatch(viewerMenuActions.onAnnotate({ name: Annotate.Arrow }));
            },
          },
          {
            label: this.translate.instant('AnnotateEllipse'),
            icon: 'icon icon-AreaEclipse',
            id: 'menu-annotate-ellipse',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Ellipse),
            command: () => {
              this.store.dispatch(viewerMenuActions.onAnnotate({ name: Annotate.Ellipse }));
            },
          },
          {
            label: this.translate.instant('AnnotateRectangle'),
            icon: 'icon icon-AreaRectangle',
            id: 'menu-annotate-rectangle',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Rectangle),
            command: () => {
              this.store.dispatch(viewerMenuActions.onAnnotate({ name: Annotate.Rectangle }));
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns an array of menu items for the annotation volume menu.
   * @returns {Promise<Array<Object>>} The array of menu items.
   */
  private _annotationVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('Annotate'),
        icon: (await this._getCurrentAnnotate()).icon,
        id: 'menu-annotate',
        styleClass: await this._isActiveAnnotate(),
        items: [
          // {
          //   label: this.translate.instant('AnnotateText'),
          //   icon: 'icon icon-Annotate',
          //   id: 'menu-annotate-textbox',
          //   styleClass: await this._isMenuActive(this.annotate$, Annotate.Text),
          // },
          {
            label: this.translate.instant('AnnotateArrow'),
            icon: 'icon icon-Arrow',
            id: 'menu-annotate-arrow',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Arrow),
            command: () => {
              this.store.dispatch(viewerMenuActions.onVolumeAnnotate({ name: Annotate.Arrow }));
            },
          },
          {
            label: this.translate.instant('AnnotateEllipse'),
            icon: 'icon icon-AreaEclipse',
            id: 'menu-annotate-ellipse',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Ellipse),
            command: () => {
              this.store.dispatch(viewerMenuActions.onVolumeAnnotate({ name: Annotate.Ellipse }));
            },
          },
          {
            label: this.translate.instant('AnnotateRectangle'),
            icon: 'icon icon-AreaRectangle',
            id: 'menu-annotate-rectangle',
            styleClass: await this._isMenuActive(this.annotate$, Annotate.Rectangle),
            command: () => {
              this.store.dispatch(viewerMenuActions.onVolumeAnnotate({ name: Annotate.Rectangle }));
            },
          },
        ],
      },
    ];
  };
  /**
   * Returns an array of menu items for sorting.
   * Each menu item has a label, icon, id, and optional styleClass.
   * The label is translated using the `translate` service.
   * @returns An array of menu items.
   */
  private _sortMenu = async () => {
    return [
      {
        label: this.translate.instant('SortInstanceNumber'),
        icon: await this._isMenuSelected(this.sortCommand$, SortCommand.InstanceNumber),
        id: 'menu-sort-instance-number',
        command: () => {
          this.store.dispatch(viewerMenuActions.onSortCommand({ command: SortCommand.InstanceNumber }));
        },
      },
      {
        label: this.translate.instant('SortSliceLocation'),
        icon: await this._isMenuSelected(this.sortCommand$, SortCommand.SliceLocation),
        id: 'menu-sort-slice-location',
        command: () => {
          this.store.dispatch(viewerMenuActions.onSortCommand({ command: SortCommand.SliceLocation }));
        },
      },
      {
        label: this.translate.instant('SortEchoTime'),
        icon: await this._isMenuSelected(this.sortCommand$, SortCommand.EchoTime),
        id: 'menu-sort-echo-time',
        command: () => {
          this.store.dispatch(viewerMenuActions.onSortCommand({ command: SortCommand.EchoTime }));
        },
        styleClass: 'menu-item--separator',
      },
      // {
      //   label: this.translate.instant('SortScope(AllStacks)'),
      //   icon: 'icon icon-Default',
      //   id: 'menu-sort-scope-all-stacks',
      // },
      {
        label: this.translate.instant('SortAscending/Descending'),
        icon: await this._getSortDirection(),
        id: 'menu-sort-ascending-descending',
        command: () => {
          this.store.dispatch(viewerMenuActions.changeSortDirection());
        },
      },
    ];
  };

  /**
   * Generates the menu items for the 2D scroll menu.
   * @returns An array of menu items.
   */
  private _scrollMenu2D = async () => {
    return [
      {
        label: this.translate.instant('Scroll'),
        icon: 'icon icon-Scroll',
        id: 'menu-scroll',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Scroll),
        command: () => {
          this.store.dispatch(viewerMenuActions.onScroll());
        },

        items: [
          {
            label: this.translate.instant('AutoScrollImage'),
            icon: 'icon icon-AutoScroll',
            id: 'menu-scroll-auto-scroll-image',
            styleClass: 'menu-item--separator',
            command: () => {
              this.broadcastService.autoPlayBroadcast(AutoPlay.Open);
            },
          },
          {
            label: this.translate.instant('ScrollOneImage'),
            icon: await this._isMenuSelected(this.scrollMode$, ScrollMode.OneImage),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollMode({ mode: ScrollMode.OneImage }));
            },
          },
          {
            label: this.translate.instant('ScrollOneImageRow'),
            icon: await this._isMenuSelected(this.scrollMode$, ScrollMode.OneImageRow),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollMode({ mode: ScrollMode.OneImageRow }));
            },
          },
          {
            label: this.translate.instant('ScrollOneImagePage'),
            icon: await this._isMenuSelected(this.scrollMode$, ScrollMode.OneImagePage),
            styleClass: 'menu-item--separator',
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollMode({ mode: ScrollMode.OneImagePage }));
            },
          },
          {
            label: this.translate.instant('ScrollSyncByImagePosition'),
            icon: await this._isMenuSelected(this.scrollSync$, ScrollSync.ImagePosition),
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollSync({ sync: ScrollSync.ImagePosition }));
            },
          },
          {
            label: this.translate.instant('ScrollSyncByImageOrder'),
            icon: await this._isMenuSelected(this.scrollSync$, ScrollSync.ImageOrder),
            styleClass: 'menu-item--separator',
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollSync({ sync: ScrollSync.ImageOrder }));
            },
          },
          {
            label: this.translate.instant('SyncLoop'),
            icon: 'icon icon-Scroll-Loop',
            styleClass: await this._isMenuActive(this.isScrollLoopActive$),
            id: 'menu-scroll-loop',
            command: () => {
              this.store.dispatch(viewerMenuActions.changeScrollLoop());
            },
          },
        ],
      },
    ];
  };

  /**
   * Retrieves the scroll menu volume.
   * @returns An array representing the scroll menu volume.
   */
  private _scrollMenuVolume = async () => {
    return [
      {
        label: this.translate.instant('Scroll'),
        icon: 'icon icon-Scroll',
        id: 'menu-scroll',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Scroll),
        command: () => {
          this.store.dispatch(viewerMenuActions.onScroll());
        },
      },
    ];
  };

  /**
   * Returns an array representing the zoom menu.
   * @returns {Promise<object[]>} The zoom menu array.
   */
  private _zoomMenu = async () => {
    return [
      {
        label: this.translate.instant('Zoom'),
        icon: 'icon icon-dwvicon_Zoom',
        id: 'menu-zoom',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Zoom),
        command: () => {
          this.store.dispatch(viewerMenuActions.onZoom());
        },
        items: [
          {
            label: this.translate.instant('ZoomFitWidth'),
            icon: 'icon icon-FitWidth',
            id: 'menu-zoom-fit-width',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ZoomFitWidth);
            },
          },
          {
            label: this.translate.instant('ZoomFitHeight'),
            icon: 'icon icon-FitHeight',
            id: 'menu-zoom-fit-height',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ZoomFitHeight);
            },
          },
          {
            label: this.translate.instant('ZoomFitAll'),
            icon: 'icon icon-FitAll',
            id: 'menu-zoom-fit-all',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ZoomFitAll);
            },
          },
          {
            label: this.translate.instant('ZoomReset'),
            icon: 'icon icon-Reset',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ResetZoom);
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns an array representing the zoom menu.
   * @returns {Promise<object[]>} The zoom menu array.
   */
  private _zoomVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('Zoom'),
        icon: 'icon icon-dwvicon_Zoom',
        id: 'menu-zoom',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Zoom),
        command: () => {
          this.store.dispatch(viewerMenuActions.onVolumeZoom());
        },
      },
    ];
  };

  /**
   * Returns the menu configuration for the WWWL (Window Width/Window Level) functionality.
   * @returns {Promise<any[]>} The menu configuration.
   */
  private _wwwlMenu = async () => {
    return [
      {
        label: this.translate.instant('WWWL'),
        icon: 'icon icon-dwvicon_WindowLevel',
        id: 'menu-wwwl',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.WWWL),
        command: () => {
          this.store.dispatch(viewerMenuActions.onWWWLActive());
        },
        items: [
          {
            label: this.translate.instant('WWWLOptimizebyROI'),
            icon: 'icon icon-AreaRectangle',
            id: 'menu-wwwl-optimize-by-roi',
            styleClass: await this._isMenuActive(this.isWWWLActiveByROI$),
            command: () => {
              this.store.dispatch(viewerMenuActions.onWWWLActiveByROI());
            },
          },
          {
            label: this.translate.instant('WWWLInvest'),
            icon: 'icon icon-invert',
            id: 'menu-wwwl-invest',
            styleClass: await this._isMenuActive(this.isWWWLInvest$),
            command: () => {
              this.broadcastService.voiBroadcast();
            },
          },
          {
            label: this.translate.instant('LUT'),
            icon: 'icon icon-LUT',
            command: () => {
              this.broadcastService.toolBroadcast(BroadcastTool.Colorbar);
            },
            //TODO reference LUT from cornerstone
            //items: await this._lutMenu(),
          },
          {
            label: this.translate.instant('WWWLOriginal'),
            icon: 'icon icon-ResetWWL',
            id: 'menu-wwwl-original-wwl',
            command: () => {
              this.broadcastService.voiBroadcast(true);
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns the menu configuration for the WWWL (Window Width/Window Level) volume option.
   * @returns {Promise<object[]>} The menu configuration.
   */
  private _wwwlVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('WWWL'),
        icon: 'icon icon-dwvicon_WindowLevel',
        id: 'menu-wwwl',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.WWWL),
        command: () => {
          this.store.dispatch(viewerMenuActions.onWWWLVolumeActive());
        },
      },
    ];
  };

  /**
   * Returns an array of menu items for the pan menu.
   * Each menu item represents a pan action with its label, icon, id, styleClass, and command.
   * The pan menu items can be customized based on the current state of the application.
   * @returns {Promise<Array<Object>>} An array of menu items for the pan menu.
   */
  private _panMenu = async () => {
    return [
      {
        label: this.translate.instant('Pan'),
        icon: 'icon icon-icons8-Hand-30',
        id: 'menu-pan',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Pan),
        command: () => {
          this.store.dispatch(viewerMenuActions.onPan());
        },
        items: [
          // {
          //   label: this.translate.instant('Left'),
          //   icon: 'icon icon-Pan-left',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.HorizontalAlign, Position.Left);
          //   },
          // },
          // {
          //   label: this.translate.instant('HCenter'),
          //   icon: 'icon icon-Pan-center',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.HorizontalAlign, Position.Center);
          //   },
          // },
          // {
          //   label: this.translate.instant('Right'),
          //   icon: 'icon icon-Pan-right',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.HorizontalAlign, Position.Right);
          //   },
          //   styleClass: 'menu-item--separator',
          // },
          // {
          //   label: this.translate.instant('Top'),
          //   icon: 'icon icon-Pan-top',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.VerticalAlign, Position.Top);
          //   },
          // },
          // {
          //   label: this.translate.instant('VCenter'),
          //   icon: 'icon icon-Pan-center',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.VerticalAlign, Position.Center);
          //   },
          // },
          // {
          //   label: this.translate.instant('Bottom'),
          //   icon: 'icon icon-Pan-bottom',
          //   command: () => {
          //     this.broadcastService.cameraBroadcast(CameraAction.VerticalAlign, Position.Bottom);
          //   },
          //   styleClass: 'menu-item--separator',
          // },
          {
            label: this.translate.instant('PanReset'),
            icon: 'icon icon-Reset',
            id: 'menu-pan-reset',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ResetPan);
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns an array of menu items for the pan volume menu.
   * @returns {Promise<Array<Object>>} The array of menu items.
   */
  private _panVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('Pan'),
        icon: 'icon icon-icons8-Hand-30',
        id: 'menu-pan',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Pan),
        command: () => {
          this.store.dispatch(viewerMenuActions.onVolumePan());
        },
      },
    ];
  };

  /**
   * Returns an array representing the rotate menu.
   * @returns {Promise<object[]>} The rotate menu array.
   */
  private _rotateMenu = async () => {
    return [
      {
        label: this.translate.instant('Rotate'),
        icon: 'icon icon-Rotate-Manual',
        id: 'menu-rotate',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Rotate),
        command: () => {
          this.store.dispatch(viewerMenuActions.onRotate());
        },
        items: [
          {
            label: this.translate.instant('RotateLeft'),
            icon: 'icon icon-Rotate',
            id: 'menu-rotate-left',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.RotateLeft);
            },
          },
          {
            label: this.translate.instant('RotateRight'),
            icon: 'icon icon-RotateRight',
            id: 'menu-rotate-right',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.RotateRight);
            },
          },
          {
            label: this.translate.instant('FlipVertically'),
            icon: 'icon icon-FlipVerti',
            id: 'menu-rotate-flip-vertically',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.FlipVertical);
            },
          },
          {
            label: this.translate.instant('FlipHorizontal'),
            icon: 'icon icon-FlipHoriz',
            id: 'menu-rotate-flip-horizontal',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.FlipHorizontal);
            },
          },
          {
            label: this.translate.instant('RotateReset'),
            icon: 'icon icon-Rotate-Reset',
            id: 'menu-rotate-reset',
            command: () => {
              this.broadcastService.cameraBroadcast(CameraAction.ResetRotate);
            },
          },
        ],
      },
    ];
  };

  //Remember: Remove filter menu base requirement request
  // private _filterMenu = async () => {
  //   return [
  //     {
  //       label: this.translate.instant('FilterNone'),
  //       icon: 'icon icon-Default',
  //       id: 'menu-filter-none',
  //     },
  //     {
  //       label: this.translate.instant('FilterSharpen(Weak)'),
  //       icon: 'icon icon-Default',
  //       id: 'menu-filter-sharpen-weak',
  //     },
  //     {
  //       label: this.translate.instant('FilterSharpen(Medium)'),
  //       icon: 'icon icon-Default',
  //       id: 'menu-filter-sharpen-medium',
  //     },
  //     {
  //       label: this.translate.instant('FilterSharpen(Strong)'),
  //       icon: 'icon icon-Default',
  //       id: 'menu-filter-sharpen-strong',
  //     },
  //   ];
  // };

  // /**
  //  * Generates a submenu for the color maps.
  //  * @returns {Promise<Array<Object>>} The generated submenu.
  //  */
  // private _lutMenu = async () => {
  //   // Convert all VTK colormaps to the one supported by the colorbar which actualy
  //   // have almost the same properties.
  //   const colormaps = vtkColormaps.rgbPresetNames.map((presetName) => vtkColormaps.getPresetByName(presetName));
  //   const subMenu = colormaps.map((preset) => {
  //     const presetname = preset.Name;
  //     return {
  //       label: presetname,
  //       icon: 'icon icon-Unselect',
  //       command: () => this.broadcastService.colormapBroadcast(presetname),
  //     };
  //   });
  //   return subMenu;
  // };

  /**
   * Retrieves the menu items for synchronization.
   * @returns {Promise<Array<Object>>} The array of menu items for synchronization.
   */
  private _syncMenu = async () => {
    const menu: any = [
      {
        label: this.translate.instant('Sync'),
        icon: 'icon icon-SyncImage',
        id: 'menu-sync',
        items: [
          {
            label: this.translate.instant('SyncScopeAll'),
            icon: await this._isMenuSelected(this.syncScope$, SyncScope.All),
            command: () => this.store.dispatch(viewerMenuActions.onSyncScope({ scope: SyncScope.All })),
          },
          {
            label: this.translate.instant('SyncScopeStudy'),
            icon: await this._isMenuSelected(this.syncScope$, SyncScope.Study),
            command: () => this.store.dispatch(viewerMenuActions.onSyncScope({ scope: SyncScope.Study })),
          },
          {
            label: this.translate.instant('SyncScopeSeries'),
            icon: await this._isMenuSelected(this.syncScope$, SyncScope.Series),
            command: () => this.store.dispatch(viewerMenuActions.onSyncScope({ scope: SyncScope.Series })),
            styleClass: 'menu-item--separator',
          },
        ],
      },
    ];

    if ((await firstValueFrom(this.syncScope$)) !== SyncScope.Series) {
      menu[0].items.push(
        {
          label: this.translate.instant('SyncZoom'),
          icon: 'icon icon-dwvicon_Zoom',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncZoom()),
          styleClass: await this._isMenuActive(this.syncZoom$),
        },
        {
          label: this.translate.instant('SyncWWWL'),
          icon: 'icon icon-dwvicon_WindowLevel',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncWWWL()),
          styleClass: await this._isMenuActive(this.syncWWWL$),
        },
        {
          label: this.translate.instant('SyncPan'),
          icon: 'icon icon-icons8-Hand-30',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncPan()),
          styleClass: await this._isMenuActive(this.syncPan$),
        },
        {
          label: this.translate.instant('SyncRotate'),
          icon: 'icon icon-Rotate-Manual',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncRotate()),
          styleClass: await this._isMenuActive(this.syncRotate$),
        },
        {
          label: this.translate.instant('SyncScroll'),
          icon: 'icon icon-Scroll',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncScroll()),
          styleClass: await this._isMenuActive(this.syncScroll$),
        },
        // {
        //   label: this.translate.instant('SyncFilter'),
        //   icon: 'icon icon-Filter',
        //   id: 'menu-sync-filter',
        // },
        {
          label: this.translate.instant('SyncLUT'),
          icon: 'icon icon-LUT',
          command: () => this.store.dispatch(viewerMenuActions.changeSyncLUT()),
          styleClass: await this._isMenuActive(this.syncLUT$),
        },
      );
    } else {
      menu[0].items.push(
        {
          label: this.translate.instant('SyncZoom'),
          icon: 'icon icon-dwvicon_Zoom',
          styleClass: 'menu-item--activated',
        },
        {
          label: this.translate.instant('SyncWWWL'),
          icon: 'icon icon-dwvicon_WindowLevel',
          styleClass: 'menu-item--activated',
        },
        {
          label: this.translate.instant('SyncPan'),
          icon: 'icon icon-icons8-Hand-30',
          styleClass: 'menu-item--activated',
        },
        {
          label: this.translate.instant('SyncRotate'),
          icon: 'icon icon-Rotate-Manual',
          styleClass: 'menu-item--activated',
        },
        {
          label: this.translate.instant('SyncScroll'),
          icon: 'icon icon-Scroll',
          styleClass: 'menu-item--activated',
        },
        {
          label: this.translate.instant('SyncLUT'),
          icon: 'icon icon-LUT',
          styleClass: 'menu-item--activated',
        },
      );
    }
    return menu;
  };

  /**
   * Returns an array of menu items for the select menu.
   * @returns {Promise<Array<any>>} The array of menu items.
   */
  private _selectMenu = async () => {
    return [
      {
        label: this.translate.instant('Select'),
        icon: 'icon icon-Select',
        id: 'menu-select',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Select),
        command: () => {
          this.store.dispatch(viewerMenuActions.onSelect());
        },
        // Fix Issue: https://gitlab.com/RADTeam/vpacs/-/issues/38
        // items: [
        //   {
        //     label: this.translate.instant('SelectStack'),
        //     icon: 'icon icon-Select-Select',
        //     id: 'menu-select-select-stack',
        //   },
        //   {
        //     label: this.translate.instant('UnselectStack'),
        //     icon: 'icon icon-Select-Unselect',
        //     id: 'menu-select-unselect-stack',
        //   },
        //   {
        //     label: this.translate.instant('UnselectAllStacks'),
        //     icon: 'icon icon-Select-UnselectAll',
        //     id: 'menu-select-unselect-all-stacks',
        //   },
        // ],
      },
    ];
  };

  /**
   * Returns an array of menu items for selecting a volume.
   * @returns {Promise<Array<Object>>} The array of menu items.
   */
  private _selectVolumeMenu = async () => {
    return [
      {
        label: this.translate.instant('Select'),
        icon: 'icon icon-Select',
        id: 'menu-select',
        styleClass: await this._isMenuActive(this.toolActived$, Tools.Select),
        command: () => {
          this.store.dispatch(viewerMenuActions.onVolumeSelect());
        },
      },
    ];
  };

  /**
   * Returns an array of menu items for the 2D tools menu.
   * @returns {Promise<Array<any>>} The array of menu items.
   */
  private _toolsMenu2D = async () => {
    const isShareMenu = (await firstValueFrom(this.isShareMode$)) === false && (await firstValueFrom(this.fromDrive$)) === true;
    return [
      {
        label: this.translate.instant('Tool'),
        icon: 'icon icon-Tool',
        id: 'menu-tool',
        items: [
          {
            label: this.translate.instant('ToolMagnifierGlass'),
            icon: 'icon icon-ToolGlass',
            id: 'menu-tool-magnifier-glass',
            styleClass: await this._isMenuActive(this.isMagnify$),
            command: () => {
              this.store.dispatch(viewerMenuActions.triggerMagnify());
            },
          },
          {
            label: this.translate.instant('ToolDICOMTagsInfo'),
            icon: 'icon icon-dwvicon_OverlayInfo',
            id: 'menu-tool-dicom-tags-info',
            command: () => {
              this.broadcastService.toolBroadcast(BroadcastTool.DicomInfo);
            },
          },
          isShareMenu
            ? {
                label: this.translate.instant('ShareStudy'),
                icon: 'icon icon-share',
                command: () => {
                  this.shareCurrentStudy();
                },
              }
            : {},
          // {
          //   label: this.translate.instant('ToolPlayCine'),
          //   icon: 'icon icon-Cine',
          //   id: 'menu-tool-play-cine',
          // },
          {
            label: this.translate.instant('ToolExportImages'),
            icon: 'icon icon-ExportImage',
            id: 'menu-tool-export-images',
            command: () => {
              this.broadcastService.toolBroadcast(BroadcastTool.Export);
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns the tools menu for 3D view.
   * @returns {Promise<object[]>} The tools menu items.
   */
  private _toolsMenu3D = async () => {
    return [
      {
        label: this.translate.instant('Tool'),
        icon: 'icon icon-Tool',
        id: 'menu-tool',
        items: [
          {
            label: this.translate.instant('ToolMagnifierGlass'),
            icon: 'icon icon-ToolGlass',
            id: 'menu-tool-magnifier-glass',
            command: () => {
              this.store.dispatch(viewerMenuActions.triggerMagnify());
            },
          },
        ],
      },
    ];
  };

  /**
   * Returns the report menu items.
   * @returns An array of report menu items.
   */
  private _reportMenu = async () => {
    return [
      {
        label: this.translate.instant('Report'),
        icon: 'icon icon-Report',
        id: 'menu-report',
        styleClass: await this._isMenuActive(this.isOpenReport$),
        items: [
          {
            label: this.translate.instant('OpenReport'),
            icon: 'icon icon-ViewReport',
            id: 'menu-report-open-report',
            command: () => {
              this._openReport();
            },
          },
          {
            label: this.translate.instant('ReportPasteEntireImage'),
            icon: 'icon icon-Report-ImageEntire',
            id: 'menu-report-paste-entire-image',
            command: () => {
              this.store.dispatch(viewerMenuActions.pasteKeyImageFull());
            },
          },
          // {
          //   label: this.translate.instant('ReportPasteRegionofImage'),
          //   icon: 'icon icon-Report-ImageRegion',
          //   id: 'menu-report-paste-region-of-image',
          //   command: () => {
          //     this.store.dispatch(viewerMenuActions.pasteKeyImageRegion());
          //   },
          // },
        ],
      },
    ];
  };

  /**
   * Returns the full-screen items.
   * @returns An array of full-screen menu items.
   */
  private _fullScreen = async () => {
    return [
      {
        label: this.translate.instant('FullScreen'),
        icon: 'icon icon-icons8-Full-Screen-Filled-50',
        id: 'menu-full-screen',
        styleClass: await this._isMenuActive(this.isFullScreen$),
        command: () => this.onFullScreen(),
      },
    ];
  };

  /**
   * Returns an array of menu items for the MPR3D menu.
   * @returns {Promise<Array<Object>>} The array of menu items.
   */
  private _mpr3dMenu = async () => {
    return [
      {
        label: this.translate.instant('MPR3D'),
        icon: 'icon icon-MPR',
        id: 'menu-mpr',
        command: () => {
          this.store.dispatch(viewerMenuActions.onMPRActive());
        },
        styleClass: await this._isMenuActive(this.viewerMode$, ViewerMode.MPR),
        // items: [
        //   {
        //     label: this.translate.instant('MPR(3V)'),
        //     icon: await this._getCurrentMPRLayoutIcon(MPR_LAYOUT['3V0H']),
        //     id: 'menu-mpr-3v',
        //     styleClass: await this._getCurrentMPRLayout(MPR_LAYOUT['3V0H']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onMPRLayout({ layout: MPR_LAYOUT['3V0H'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('MPR(2H1V)'),
        //     icon: await this._getCurrentMPRLayoutIcon(MPR_LAYOUT['2H1V']),
        //     id: 'menu-mpr-2h1v',
        //     styleClass: await this._getCurrentMPRLayout(MPR_LAYOUT['2H1V']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onMPRLayout({ layout: MPR_LAYOUT['2H1V'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('MPR(3H3D)'),
        //     icon: await this._getCurrentMPRLayoutIcon(MPR_LAYOUT['3H1D']),
        //     id: 'menu-mpr-3h3d',
        //     styleClass: await this._getCurrentMPRLayout(MPR_LAYOUT['3H1D']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onMPRLayout({ layout: MPR_LAYOUT['3H1D'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('MPR(3D)'),
        //     icon: await this._getCurrentMPRLayoutIcon(MPR_LAYOUT['1D0D']),
        //     id: 'menu-mpr-3h3d',
        //     styleClass: await this._getCurrentMPRLayout(MPR_LAYOUT['1D0D']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onMPRLayout({ layout: MPR_LAYOUT['1D0D'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('MPR(2D)'),
        //     icon: await this._getCurrentMPRVisble2DIcon(),
        //     id: 'menu-mpr-2d',
        //     styleClass: await this._getCurrentMPRVisble2D(),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onMPRVisible2D());
        //     },
        //   },
        // ],
      },
    ];
  };

  /**
   * Returns an array of menu items for the MPR3D mode.
   * @returns {Promise<Array<object>>} The array of menu items.
   */
  private _mpr3dOnModeMenu = async () => {
    return [
      {
        label: this.translate.instant('MPR3D'),
        icon: 'icon icon-MPR',
        id: 'menu-mpr',
        styleClass: await this._isMenuActive(this.viewerMode$, ViewerMode.MPR),
        command: () => {
          this.store.dispatch(viewerMenuActions.onMPRDeactive());
        },
      },
    ];
  };

  /**
   * Returns an array of fusion menu items.
   * @returns {Promise<Array<Object>>} The fusion menu items.
   */
  private _fusionMenu = async () => {
    return [
      {
        label: this.translate.instant('Fusion'),
        icon: 'icon icon-Fusion',
        id: 'menu-fusion',
        command: () => {
          this.store.dispatch(viewerMenuActions.onFusionActive());
        },
        styleClass: await this._isMenuActive(this.viewerMode$, ViewerMode.Fusion),
        // items: [
        //   {
        //     label: this.translate.instant('Fusion(3V)'),
        //     icon: await this._getCurrentFusionLayoutIcon(FUSION_LAYOUT['3V0H']),
        //     id: 'menu-fusion-3v',
        //     styleClass: await this._getCurrentFusionLayout(FUSION_LAYOUT['3V0H']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionLayout({ layout: FUSION_LAYOUT['3V0H'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('Fusion(2V2H)'),
        //     icon: await this._getCurrentFusionLayoutIcon(FUSION_LAYOUT['2V2H']),
        //     id: 'menu-fusion-2v2h',
        //     styleClass: await this._getCurrentFusionLayout(FUSION_LAYOUT['2V2H']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionLayout({ layout: FUSION_LAYOUT['2V2H'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('Fusion(2H3V)'),
        //     icon: await this._getCurrentFusionLayoutIcon(FUSION_LAYOUT['2H3V']),
        //     id: 'menu-fusion-2h3v',
        //     styleClass: await this._getCurrentFusionLayout(FUSION_LAYOUT['2H3V']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionLayout({ layout: FUSION_LAYOUT['2H3V'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('Fusion(2H2V)'),
        //     icon: await this._getCurrentFusionLayoutIcon(FUSION_LAYOUT['2H2V']),
        //     id: 'menu-fusion-2h2v',
        //     styleClass: await this._getCurrentFusionLayout(FUSION_LAYOUT['2H2V']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionLayout({ layout: FUSION_LAYOUT['2H2V'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('Fusion(4V)'),
        //     icon: await this._getCurrentFusionLayoutIcon(FUSION_LAYOUT['4V0H']),
        //     id: 'menu-fusion-4v',
        //     styleClass: await this._getCurrentFusionLayout(FUSION_LAYOUT['4V0H']),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionLayout({ layout: FUSION_LAYOUT['4V0H'] }));
        //     },
        //   },
        //   {
        //     label: this.translate.instant('Fusion(2D)'),
        //     icon: await this._getCurrentFusionVisble2DIcon(),
        //     id: 'menu-fusion-2d',
        //     styleClass: await this._getCurrentFusionVisble2D(),
        //     command: () => {
        //       this.store.dispatch(viewerMenuActions.onFusionVisible2D());
        //     },
        //   },
        // ],
      },
    ];
  };

  /**
   * Returns an array of menu items for the Fusion mode.
   * @returns {Promise<Array<any>>} The array of menu items.
   */
  private _fusionOnModeMenu = async () => {
    return [
      {
        label: this.translate.instant('Fusion'),
        icon: 'icon icon-Fusion',
        id: 'menu-fusion',
        class: await this._isMenuActive(this.viewerMode$, ViewerMode.Fusion),
        command: () => {
          this.store.dispatch(viewerMenuActions.onFusionDeactive());
        },
        styleClass: await this._isMenuActive(this.viewerMode$, ViewerMode.Fusion),
      },
    ];
  };
  //#endregion

  //#region Others
  /**
   * Logs out the user.
   * If the login mode is set to Firebase, it dispatches a logout action.
   */
  protected logout = async (): Promise<void> => {
    const loginMode = await firstValueFrom(this.store.select(authQuery.selectLoginModeInfo));
    if (loginMode === LoginMode.firebase) {
      this.store.dispatch(firebaseAuthActions.logout());
    }
  };

  /**
   * Opens the setting dialog.
   * @param visible - Specifies whether the setting dialog should be visible or not. Default value is true.
   */
  protected openSettingDialog(visible: boolean = true): void {
    this.store.dispatch(shellActions.changeSettingDialogVisible({ visible }));
  }

  /**
   * Handler for the mouseleave event.
   * Simulates a click on the body element.
   */
  protected onMouseleave = (): void => {
    window.document.body.click();
  };

  /**
   * Handles the fullscreen functionality of the menu component.
   */
  protected onFullScreen = (): void => {
    // this._toolbarService.fullscreenBrowser.next();
    const doc = window.document as any;
    const docEl = doc.documentElement as any;

    const requestFullScreen = docEl.requestFullscreen || docEl.webkitRequestFullScreen;
    const cancelFullScreen = doc.exitFullscreen || doc.webkitExitFullscreen;

    if (!doc.fullscreenElement && !doc.webkitFullscreenElement) {
      requestFullScreen.call(docEl);
      this.store.dispatch(viewerMenuActions.onFullScreenCommand({ isFullScreen: true }));
    } else {
      cancelFullScreen.call(doc);
      this.store.dispatch(viewerMenuActions.onFullScreenCommand({ isFullScreen: false }));
    }
  };

  /**
   * Checks if the browser is in full-screen mode and dispatches the appropriate action.
   */
  private _isBrowserFullScreen = (): void => {
    if (window.innerHeight === screen.height) {
      this.store.dispatch(viewerMenuActions.onFullScreenCommand({ isFullScreen: true }));
    } else {
      this.store.dispatch(viewerMenuActions.onFullScreenCommand({ isFullScreen: false }));
    }
  };

  /**
   * Opens a report using the specified parameters.
   */
  private _openReport = async (): Promise<void> => {
    const isOpenReport = await firstValueFrom(this.isOpenReport$);
    if (isOpenReport) {
      this.sharedService.toastMessage(Severity.warn, this.translate.instant('ReportAlreadyOpened'));
      this._window.focusReport();
      return;
    }
    const isShareMode = this.route.url.includes(APP_ROUTE_BASE.SHARE_VIEWVER);
    const params = <GoogleParams>RouteHelpers.Instance.collectRouteQueryParams(this.route);
    this.store.dispatch(viewerMenuActions.openReport({ params, isShareMode }));
    window.removeEventListener('message', this._listenReportWindow);
    window.addEventListener('message', this._listenReportWindow, false);
  };

  /**
   * Listens for messages from the report window and handles them accordingly.
   * @param event - The message event.
   */
  private _listenReportWindow = async (event) => {
    const origin = window.location.origin;
    // Check the origin of the message
    if (event.origin !== origin) {
      // Not the expected origin: Reject the message!
      return;
    }
    // Check the data of the message
    if (event.data === 'closed') {
      this.store.dispatch(viewerMenuActions.closeReport());
      console.log('Report window closed');
    }
  };

  /**
   * Shares the current study after user confirmation.
   * 
   * This method displays a confirmation dialog to the user. If the user confirms,
   * it shows a preloader indicating that the study is being shared and then
   * dispatches an action to share the study.
   * 
   * @private
   * @async
   * @returns {Promise<void>} A promise that resolves when the sharing process is initiated.
   */
  private shareCurrentStudy = async () => {
    const confirm = await this.sharedService.confirmDialog(Severity.info, this.translate.instant('ShareStudyConfirm'));
    if (confirm) {
      this.sharedService.preloader(true, this.translate.instant('SharingStudy'));
      this.store.dispatch(viewerDriveActions.shareStudy());
    }
  };
  //#endregion
}
