import { Injectable } from '@angular/core';
import { inject } from '@angular/core';
import {
  Firestore,
  collectionData,
  collection,
  DocumentData,
  getDocs,
  where,
  WhereFilterOp,
  query,
  Timestamp,
  updateDoc,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  FieldPath,
  runTransaction,
  limit,
  startAfter,
  getCountFromServer,
  orderBy,
} from '@angular/fire/firestore';
import { METADATA_KEY, QUERY_OPERATOR } from '@app/@core';
import { Logger } from '@app/@shared';
import { Store } from '@ngrx/store';
import { OrderByDirection, Query, QueryFieldFilterConstraint, QuerySnapshot, documentId, startAt } from 'firebase/firestore';

import { firstValueFrom } from 'rxjs';
import { ICondition } from '../models/IBase';
import { MAX_CHUNK_SIZE } from '../constants';
const log = new Logger('firebase Service');

@Injectable({ providedIn: 'root' })
export class FireStoreService {
  constructor(private firestore: Firestore) {}

  /**
   * Get all document data of collection
   * @param {string} collectionName firestore collection name
   * @returns {Promise<any>} document data as {@link DocumentData}
   */
  public async getAllDataOfCollection(collectionName: string, rows: number = 0, page: number = 0): Promise<any> {
    try {
      if (rows === 0 && page === 0) {
        const itemsCollection = collection(this.firestore, collectionName);
        const data = await firstValueFrom(collectionData(itemsCollection));
        return data !== undefined ? data : [];
      } else {
        const itemsCollection = collection(this.firestore, collectionName);

        let q;
        if (page > 1) {
          //First page of docs is not filtered by any condition so we can use limit to improve performance
          q = query(itemsCollection, limit(rows));
        } else {
          // Query the first page of docs
          const lastVisible = rows * page - 1;
          //Next pages
          q = query(itemsCollection, startAfter(lastVisible), limit(rows));
        }
        const querySnapshot = await getDocs(q);
        let res;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          res = doc.data();
          res[METADATA_KEY.ID] = doc.id;
        });
        return res;
      }
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  /**
   * Get document data of collection by key {@link KEY_QUERY} of database
   * @param {string} collectionName firestore collection name
   * @param {string} value value compair for queries existed document on database
   * @returns {Promise<any>} document data as {@link DocumentData}
   */
  public async getDataOfCollectionByUserId(collectionName: string, userId: string, rows: number = 0, page: number = 0): Promise<DocumentData | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereCondition = where(METADATA_KEY.CREATE_BY, '==', userId);
      let q;
      // Not apply pagination when `rows` equal zero
      if (rows === 0 && page === 0) {
        q = query(itemsCollection, whereCondition);
      } else {
        // Apply pagination when `rows` greater than zero and `page` not equals zero
        if (page === 1) {
          //First page of docs is not filtered by any condition so we can use limit to improve performance
          q = query(itemsCollection, whereCondition, limit(rows));
        } else {
          // Query the first page of docs
          const lastVisible = rows * page - 1;
          //Next pages
          q = query(itemsCollection, whereCondition, startAfter(lastVisible), limit(rows));
        }
      }
      const querySnapshot = await getDocs(q);
      let res;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        res = doc.data();
        res[METADATA_KEY.ID] = doc.id;
      });
      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Retrieves data from a Firestore collection based on a specified field value.
   *
   * @param collectionName - The name of the Firestore collection.
   * @param field - The field to filter the collection by.
   * @param value - The value to compare against the field.
   * @param operator - The operator to use for the comparison (default: '==').
   * @param userId - The user ID to further filter the collection by (default: '').
   * @returns A Promise that resolves to the retrieved document data, or undefined if an error occurs.
   */
  public async getDataOfCollectionByFieldValue(collectionName: string, field: string, value: string, operator: WhereFilterOp = '==', userId: string = ''): Promise<DocumentData | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereCondition = where(field, operator, value);
      let q = query(itemsCollection, whereCondition);
      if (userId !== '') {
        q = query(itemsCollection, whereCondition, where(METADATA_KEY.CREATE_BY, '==', userId));
      }

      const querySnapshot = await getDocs(q);
      let res;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        res = doc.data();
        res[METADATA_KEY.ID] = doc.id;
      });
      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Get document data of collection by document key
   * @param {string} collectionName firestore collection name
   * @param {string} key firestore document key
   * @returns {Promise<any>} document data as {@link DocumentData}
   */
  public async getDataOfCollectionByKey(collectionName: string, key: string): Promise<DocumentData | undefined> {
    try {
      const querySnapshot = await getDoc(doc(collection(this.firestore, collectionName), key));
      return querySnapshot.data();
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Retrieves data of a collection based on an array of keys.
   * @param collectionName - The name of the collection to retrieve data from.
   * @param keys - An array of keys to filter the collection by.
   * @returns A promise that resolves to an array of DocumentData objects.
   */
  public async getDataOfCollectionByKeys(collectionName: string, keys: string[]): Promise<DocumentData[]> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const res: any = [];
      // fetch data in chunks if the number of keys exceeds the maximum chunk size
      if (keys.length > MAX_CHUNK_SIZE) {
        const promises: Promise<QuerySnapshot<DocumentData, DocumentData>>[] = [];

        for (let i = 0; i < keys.length; i += MAX_CHUNK_SIZE) {
          const chunk = keys.slice(i, i + MAX_CHUNK_SIZE);

          const whereCondition = where(documentId(), 'in', chunk);
          const q = query(itemsCollection, whereCondition);
          promises.push(getDocs(q));
        }
        const querySnapshots = await Promise.all(promises);
        querySnapshots.flatMap((snapshot) =>
          snapshot.docs.map((doc) => {
            const data: any = doc.data();
            data[METADATA_KEY.ID] = doc.id;
            res.push(data);
          }),
        );

        return res;
      }
      // fetch data in a single query if the number of keys is less than or equal to the maximum chunk size
      const whereCondition = where(documentId(), 'in', keys);
      const q = query(itemsCollection, whereCondition);
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data: any = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });
      return res;
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  /**
   * Retrieves data from a Firestore collection based on a specified field value.
   *
   * @param collectionName - The name of the Firestore collection.
   * @param field - The field to filter the collection by.
   * @param value - The value to filter the collection by.
   * @param operator - The operator to use for the filter condition.
   * @param direction - The direction to order the results.
   * @param rows - The number of rows to retrieve per page (default: 0).
   * @param page - The page number to retrieve (default: 0).
   * @param lastVisibleId - The ID of the last visible document for pagination (default: '').
   * @returns A Promise that resolves to an array of documents matching the specified criteria, or undefined if an error occurs.
   */
  public async getDatasOfCollectionByFieldValue(
    collectionName: string,
    field: string,
    value: string,
    operator: WhereFilterOp,
    direction: OrderByDirection = 'desc',
    rows: number = 0,
    page: number = 0,
    lastVisibleId: string = '',
  ): Promise<DocumentData | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereCondition = where(field, operator, value);
      let q;
      // Not apply pagination when `rows` equal zero
      if (rows === 0 && page === 0) {
        q = query(itemsCollection, whereCondition);
      } else {
        // Apply pagination when `rows` greater than zero and `page` not equals zero
        if (page === 1) {
          //First page of docs is not filtered by any condition so we can use limit to improve performance
          q = query(itemsCollection, whereCondition, orderBy(METADATA_KEY.CREATE_AT, direction), limit(rows));
        } else {
          //get lastVisible document
          const lastVisibleDoc = await getDoc(doc(itemsCollection, lastVisibleId));
          //Next pages
          q = query(itemsCollection, whereCondition, orderBy(METADATA_KEY.CREATE_AT, direction), startAfter(lastVisibleDoc), limit(rows));
        }
      }
      const querySnapshot = await getDocs(q);
      const res: any = [];
      querySnapshot.forEach((doc) => {
        const data: any = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });

      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Retrieves the total number of records in a Firestore collection.
   * If a field, value, and operator are provided, it counts the number of records that match the specified condition.
   * If no field, value, and operator are provided, it counts the total number of records in the collection.
   *
   * @param collectionName - The name of the Firestore collection.
   * @param field - The field to filter the records by (optional).
   * @param value - The value to compare the field against (optional).
   * @param operator - The operator to use for the comparison (optional, defaults to '!=').
   * @returns A Promise that resolves to the total number of records in the collection.
   */
  public countTotalRecord = async (collectionName: string, field: string = '', value: string = '', operator: WhereFilterOp = '!='): Promise<number> => {
    const collectionItems = collection(this.firestore, collectionName);
    if (field === '' && value === '') {
      const snapshot = await getCountFromServer(collectionItems);
      return snapshot.data().count;
    } else {
      const q = query(collectionItems, where(field, operator, value));
      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    }
  };

  /**
   * Add new document into collection
   * @param {string} collectionName firestore collection name
   * @param {string} docData document data object
   * @returns {Promise<string>} new document uid
   */
  public async addData(collectionName: string, docData: Object): Promise<string | undefined> {
    try {
      // add metadata to firestore document
      const itemsCollection = collection(this.firestore, collectionName);
      docData[METADATA_KEY.CREATE_AT] = new Date();
      const res = await addDoc(itemsCollection, docData);
      return res.id;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Searches for documents in a Firestore collection based on the specified conditions.
   *
   * @param collectionName - The name of the Firestore collection to search in.
   * @param conditions - An array of conditions to filter the search results.
   * @param userId - The user ID to filter the search results by.
   * @param direction - The direction to order the search results.
   * @param rows - The number of rows to retrieve per page. Defaults to 0.
   * @param page - The page number to retrieve. Defaults to 0.
   * @param lastVisibleId - The ID of the last visible document. Defaults to an empty string.
   * @returns A promise that resolves to an array of matching documents, or undefined if an error occurs.
   */
  public async search(
    collectionName: string,
    conditions: ICondition[],
    userId: string = '',
    direction: OrderByDirection = 'desc',
    rows: number = 0,
    page: number = 0,
    lastVisibleId: string = '',
  ): Promise<DocumentData[] | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereConditions: QueryFieldFilterConstraint[] = [];
      if (userId !== '') {
        whereConditions.push(where(METADATA_KEY.CREATE_BY, '==', userId));
      }
      // Add conditions to the query
      conditions.forEach((condition) => {
        const { field, operator, value } = condition;
        if (value !== '' || value.length > 0 || value !== null || value !== undefined) {
          if (value instanceof Date) {
            const timestamp = Timestamp.fromDate(value);
            whereConditions.push(where(field, operator, timestamp));
          } else {
            whereConditions.push(where(field, operator, value));
          }
        }
      });
      let q: Query<DocumentData>;

      // Not apply pagination when `rows` equal zero
      if (rows === 0 && page === 0) {
        q = query(itemsCollection, ...whereConditions);
      } else {
        // Apply pagination when `rows` greater than zero and `page` not equals zero
        if (page === 1) {
          //First page of docs is not filtered by any condition so we can use limit to improve performance
          q = query(itemsCollection, ...whereConditions, orderBy(METADATA_KEY.CREATE_AT, direction), limit(rows));
        } else {
          //get lastVisible document
          const lastVisibleDoc = await getDoc(doc(itemsCollection, lastVisibleId));

          //Next pages
          q = query(itemsCollection, ...whereConditions, orderBy(METADATA_KEY.CREATE_AT, direction), startAfter(lastVisibleDoc), limit(rows));
        }
      }
      const querySnapshot = await getDocs(q);
      const res: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        const data: DocumentData = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });

      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Searches for documents in a Firestore collection based on a single condition.
   *
   * @param collectionName - The name of the Firestore collection to search in.
   * @param condition - The condition to apply when searching for documents.
   * @param userId - (Optional) The user ID to filter the search results by.
   * @returns A promise that resolves to an array of matching documents, or undefined if an error occurs.
   */
  public async searchOneCondition(collectionName: string, condition: ICondition, userId: string = ''): Promise<DocumentData[] | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereConditions: QueryFieldFilterConstraint[] = [];
      if (userId !== '') {
        whereConditions.push(where(METADATA_KEY.CREATE_BY, '==', userId));
      }
      whereConditions.push(where(condition.field, condition.operator, condition.value));
      const q = query(itemsCollection, ...whereConditions);
      const querySnapshot = await getDocs(q);
      const res: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        const data: DocumentData = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });

      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Searches for documents in a Firestore collection based on the specified conditions on range.
   *
   * @param collectionName - The name of the Firestore collection to search in.
   * @param conditions - An array of conditions to filter the search results.
   * @param userId - (optional) The user ID to filter the search results by.
   * @returns A promise that resolves to an array of matching documents, or undefined if an error occurs.
   */
  public async searchRange(collectionName: string, conditions: ICondition[], userId: string = ''): Promise<DocumentData[] | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereConditions: QueryFieldFilterConstraint[] = [];
      if (userId !== '') {
        whereConditions.push(where(METADATA_KEY.CREATE_BY, '==', userId));
      }
      conditions.forEach((condition) => {
        whereConditions.push(where(condition.field, condition.operator, condition.value));
      });
      const q = query(itemsCollection, ...whereConditions);
      const querySnapshot = await getDocs(q);
      const res: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        const data: DocumentData = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });

      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Searches for documents in a Firestore collection based on the search term with string field.
   *
   * @param collectionName - The name of the Firestore collection to search in.
   * @param conditions - The conditions to apply for the search.
   * @param userId - (Optional) The user ID to filter the search results by.
   * @returns A promise that resolves to an array of matching documents, or undefined if an error occurs.
   */
  public async searchTerm(collectionName: string, conditions: ICondition, userId: string = ''): Promise<DocumentData[] | undefined> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const whereConditions: QueryFieldFilterConstraint[] = [];
      if (userId !== '') {
        whereConditions.push(where(METADATA_KEY.CREATE_BY, '==', userId));
      }
      whereConditions.push(where(conditions.field, '>=', conditions.value));
      whereConditions.push(where(conditions.field, '<=', conditions.value + '\uf8ff'));
      const q = query(itemsCollection, ...whereConditions);
      const querySnapshot = await getDocs(q);
      const res: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        const data: DocumentData = doc.data();
        data[METADATA_KEY.ID] = doc.id;
        res.push(data);
      });

      return res;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }
  /**
   * update existed document data by key uid
   * @param {string} collectionName firestore collection name
   * @param {string} key document key uid
   * @param {string} docData document data object
   * @returns {Promise<any>} resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
   */
  public async updateData(collectionName: string, key: string, docData: any, userId: string): Promise<any> {
    try {
      const itemsCollection = collection(this.firestore, collectionName);
      const docRef = doc(itemsCollection, key);
      const newData = await runTransaction(this.firestore, async (transction) => {
        // add metadata to firestore document
        docData[METADATA_KEY.UPDATE_AT] = new Date();
        docData[METADATA_KEY.UPDATE_BY] = userId;
        await updateDoc(docRef, docData, { merge: true });
        //return new data updated on firebase
        const updatedData = await transction.get(docRef);
        const res = updatedData.data() || {};
        res[METADATA_KEY.ID] = updatedData.id;
        return res;
      });
      return newData;
    } catch (error) {
      log.error(error);
    }
  }

  /**
   * delete existed document data by key uid
   * @param {string} collectionName firestore collection name
   * @param {string} key document key uid
   * @returns {Promise<void>} resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
   */
  public async deleteData(collectionName: string, key: string): Promise<void> {
    try {
      // add metadata to firestore document
      const itemsCollection = collection(this.firestore, collectionName);
      await deleteDoc(doc(itemsCollection, key));
    } catch (error) {
      log.error(error);
    }
  }

  /**
   * Soft deletes data in Firestore.
   * @param collectionName - The name of the collection where the document resides.
   * @param key - The key of the document to be soft deleted.
   * @param userID - The ID of the user performing the soft delete.
   * @returns A Promise that resolves to void.
   */
  public async softDeleteData(collectionName: string, key: string, userID: string): Promise<void> {
    try {
      // add metadata to firestore document
      const itemsCollection = collection(this.firestore, collectionName);
      const docRef = doc(itemsCollection, key);
      const docData: any = {
        [METADATA_KEY.DELETE_AT]: new Date(),
        [METADATA_KEY.DELETE_BY]: userID,
      };
      await runTransaction(this.firestore, async (transction) => {
        await updateDoc(docRef, docData, { merge: true });
        //return new data updated on firebase
        const updatedData = await transction.get(docRef);
        const res = updatedData.data() || {};
        res[METADATA_KEY.ID] = updatedData.id;
        return res;
      });
    } catch (error) {
      log.error(error);
    }
  }
}
