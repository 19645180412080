//#region  Import
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Logger, Severity, SharedService } from '@app/@shared';
import { DownloadQueueService, ToolsService, BroadcastService } from '@app/viewer/services';
import { ViewerGeneralQuery, viewerGeneralActions } from '@app/viewer/store/general';
import { ViewerLayoutQuery, layoutActions } from '@app/viewer/store/layout';
import { EVENTS, Enums, Types, cache, Types as csTypes, triggerEvent } from '@cornerstonejs/core';
import { utilities as toolUti, Enums as toolEnums } from '@cornerstonejs/tools';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';
import { ITileLayout, IVolumeLayout, IStackImage, ISeriesToStack, ITileInfo, IActiveTile } from '@app/viewer/models';
import { SynchronizerService } from '@app/viewer/services/synchronizer.service';
import { CPU_RENDER_MODALITY, KeyName, CornerstoneService, IImage, MODALITY_NAME, Threads } from '@app/@core';
import {
  LAYOUT_COLS_INDEX,
  LAYOUT_ROWS_INDEX,
  VIEWPORT_INDEX_SEPERATOR,
  ViewerMode,
  Provider,
  MPR_VIEWPORT_ID,
  FUSION_VIEWPORT_ID,
  BroadcastTool,
  MOUSE_MOVE_DISTANCE,
  MouseButton,
  AutoPlay,
  DEFAULT_TILE_LAYOUT,
  Tools,
  MPR_VIEWPORT_CONFIG,
  FUSION_VIEWPORT_CONFIG,
  ORTHOGRAPHIC_STACK_SEPERATOR,
  SERIES_UID_VOLUME_SEPERATOR,
  MODALITY_INDEX,
  FUSION_CT_ID,
  FUSION_PT_ID,
  FUSION_ID,
  FUSION_MIP_ID,
  ORIENTATION,
  ScrollMode,
} from '@app/viewer/contants';
import { CornerstoneQuery } from '@app/viewer/store/cornerstone';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { ViewerMenuQuery } from '@app/viewer/store/menu';
import { TileComponent } from '../tile/tile.component';
import { stackScroll } from '@app/viewer/helpers';

//#endregion
const log = new Logger('Viewport-stack');

@Component({
  selector: 'app-viewport-stack',
  templateUrl: './stack.component.html',
  styleUrls: ['../viewport.component.scss', './stack.component.scss'],
})
/**
 * Represents a stack component in the viewer.
 */
export class StackComponent implements AfterViewInit, OnInit, OnDestroy {
  //#region Props

  @Input() stackIndex: string;
  @Input() stackLayout: string;
  @Input() activated: boolean;
  @Input() index: number;
  @Output() afterImageRender = new EventEmitter<any>();
  @Output() initStack = new EventEmitter<any>();

  @ViewChildren('tile') tileChildren: QueryList<TileComponent>;

  public seriesUid: string;
  public studyUid: string;
  public totalImages: number = 0;
  public imageIndex: number = -1;

  protected strategy$ = of('immediate');
  protected volumeLayout$: Observable<IVolumeLayout | undefined>;
  protected tileLayout$: Observable<ITileLayout | undefined>;
  protected selectedStack$: Observable<string | undefined>;
  protected activeTool$: Observable<Tools>;
  protected isScrollLoop$: Observable<boolean>;
  protected tileLayout: string[];
  protected tileLayoutString: string;
  protected downloadedImage$: Observable<string[] | undefined>;
  protected viewportInfo: ISeriesToStack | undefined;
  protected tileInfo: ITileInfo[] = [];
  private _displayViewport$: Observable<ISeriesToStack | undefined>;
  private _dicomDataSubs$: Subscription;
  private _downloadImageSubs$: Subscription;
  private _tileLayoutSubs$: Subscription;
  private _exportImageSubs$: Subscription;
  private _autoPlaySubs$: Subscription;
  private _displayViewportSubs$: Subscription;
  private _isAutoPlay: boolean = false;
  private _isKeyPress: boolean = false;
  private _isMousePress: boolean = false;
  private _mouseDownPosition = {
    x: 0,
    y: 0,
  };
  readonly ViewportType: typeof Enums.ViewportType = Enums.ViewportType;
  readonly ViewerMode: typeof ViewerMode = ViewerMode;
  readonly MPR_VIEWPORT_ID: typeof MPR_VIEWPORT_ID = MPR_VIEWPORT_ID;
  readonly FUSION_VIEWPORT_ID: typeof FUSION_VIEWPORT_ID = FUSION_VIEWPORT_ID;
  private _currentImageIds: string[] = [];
  private _renderedImage: boolean = false;

  //#endregion
  constructor(
    private store: Store,
    private cornerstoneService: CornerstoneService,
    private synchronizerService: SynchronizerService,
    private toolsService: ToolsService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private broadcastService: BroadcastService,
    private changeRef: ChangeDetectorRef,
  ) {}

  //#region Angular life cycle Region
  /**
   * Initializes the component.
   */
  ngOnInit(): void {}

  /**
   * Lifecycle hook that is called after the component's view has been fully initialized.
   * It is called only once after the first ngAfterContentChecked().
   * Use this hook to perform any initialization tasks that require the view to be fully rendered.
   */
  ngAfterViewInit() {
    this.tileLayout$ = this.store.select(ViewerLayoutQuery.selectTileLayoutByStackIndex(this.stackIndex));
    // this._dicomDataSubs$ = this._seriesUid$.subscribe((seriesUid) => this._onLoadDicomData(seriesUid));
    this._tileLayoutSubs$ = this.tileLayout$.subscribe((layout) => this._onTileLayoutChange(layout));
    this._exportImageSubs$ = this.broadcastService.tool.subscribe((tool) => {
      if (tool === BroadcastTool.Export) {
        this._exportImage();
      }
    });
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from any active subscriptions.
   */
  ngOnDestroy() {
    log.debug(`destroy stack ${this.stackIndex}`);
    this._tileLayoutSubs$?.unsubscribe();
    this._unsubscribeAll();
  }

  /**
   * Unsubscribes from all active subscriptions and removes tools associated with the current stack index.
   *
   * This method performs the following actions:
   * - Unsubscribes from `_dicomDataSubs$`, `_downloadImageSubs$`, and `_exportImageSubs$` if they are active.
   * - Removes tools associated with the current `stackIndex` for both `ViewerMode.MPR` and `ViewerMode.Fusion`.
   *
   * @private
   */
  private _unsubscribeAll() {
    this._dicomDataSubs$?.unsubscribe();
    this._downloadImageSubs$?.unsubscribe();
    this._exportImageSubs$?.unsubscribe();
    this.toolsService.removeTools(this.stackIndex, ViewerMode.MPR);
    this.toolsService.removeTools(this.stackIndex, ViewerMode.Fusion);
  }

  /**
   * Checks if all tiles have been initialized.
   * @param totalTile The total number of tiles.
   * @returns A promise that resolves to true when all tiles have been initialized.
   */
  private _checkTileInit = async (totalTile: number) => {
    return new Promise((rs) => {
      const intervalCheck = setInterval(() => {
        if (this.tileChildren.length >= totalTile) {
          clearInterval(intervalCheck);
          rs(true);
        }
      }, 10);
    });
  };
  //#endregion
  //#region Handle subscription

  /**
   * Handles the change event of the tile layout.
   * @param tileLayout The new tile layout.
   */
  private _onTileLayoutChange = async (tileLayout: ITileLayout | undefined) => {
    if (tileLayout === undefined) {
      return;
    }
    const isWating = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectIsWatingRender));
    if (isWating && this._renderedImage) {
      return;
    }
    const seriesUid = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesUidViewportByStackIndex(this.stackIndex)));
    this._displayViewport$ = this.store.select(ViewerGeneralQuery.selectStackViewportType(this.stackIndex));
    const viewportInfo = await firstValueFrom(this._displayViewport$);
    if (viewportInfo === undefined || seriesUid === '') {
      return;
    }

    this.viewportInfo = viewportInfo;
    this.seriesUid = seriesUid;
    this._unsubscribeRenderer();
    //Incase provider is google, we need to load data from google drive instead of dicom web
    switch (viewportInfo?.mode) {
      case ViewerMode.Stack2D:
      default:
        this.tileInfo = this._generate2DTileInfo(tileLayout);
        await this._checkTileInit(tileLayout.total);
        await this._loadStack(seriesUid);
        break;
      case ViewerMode.MPR:
        this.tileInfo = this._generateMPRTileInfo(tileLayout);
        await this._checkTileInit(tileLayout.total);
        break;
      case ViewerMode.Fusion:
        this.tileInfo = this._generateFusionTileInfo(tileLayout);
        await this._checkTileInit(tileLayout.total);
        break;
    }
    await this._displayImage(tileLayout, viewportInfo);
    //Subscribe event when rerender image
    //NOTE: check memory leak if change multi
    this._autoPlaySubs$ = this.broadcastService.autoPlay.subscribe((autoPlay) => {
      this._onAutoPlayBroadcast(autoPlay);
    });
    this._displayViewportSubs$ = this.broadcastService.changeDisplayViewport.subscribe((isChangeAll: boolean) => {
      this._onChangeDisplayViewport(isChangeAll);
    });
    this.selectedStack$ = this.store.select(ViewerLayoutQuery.selectStackSelectedSeriesUid(this.stackIndex));
    this.activeTool$ = this.store.select(ViewerMenuQuery.selectActiveTool);
    this.isScrollLoop$ = this.store.select(ViewerMenuQuery.selectIsScrollLoop);
    this._renderedImage = true;
  };

  /**
   * Generates an array of tile information based on the provided tile layout.
   * @param tileLayout The layout of the tiles.
   * @returns An array of tile information.
   */
  private _generate2DTileInfo = (tileLayout: ITileLayout): ITileInfo[] => {
    const layout: ITileInfo[] = [];
    for (let index = 0; index < tileLayout.total; index++) {
      const stack: ITileInfo = {
        tileId: `${this.stackIndex}${VIEWPORT_INDEX_SEPERATOR}${index}`,
        tileIndex: `${this.stackIndex}${VIEWPORT_INDEX_SEPERATOR}${index}`,
        viewportType: Enums.ViewportType.STACK,
        config: undefined,
      };
      layout.push(stack);
    }
    return layout;
  };

  /**
   * Generates an array of tile information based on the given tile layout.
   *
   * @param tileLayout - The layout of the tiles.
   * @returns An array of tile information.
   */
  private _generateMPRTileInfo = (tileLayout: ITileLayout): ITileInfo[] => {
    const layout: ITileInfo[] = [];
    const viewportInputArray: any[] = cloneDeep(MPR_VIEWPORT_CONFIG[tileLayout.layoutString]);
    for (let index = 0; index < viewportInputArray.length; index++) {
      const stack: ITileInfo = {
        tileId: viewportInputArray[index].viewportId,
        tileIndex: `${this.stackIndex}${VIEWPORT_INDEX_SEPERATOR}${index}`,
        viewportType: viewportInputArray[index].type,
        config: viewportInputArray[index],
      };
      layout.push(stack);
    }
    return layout;
  };

  /**
   * Generates an array of tile information based on the given tile layout.
   *
   * @param tileLayout - The tile layout to generate tile information for.
   * @returns An array of tile information.
   */
  private _generateFusionTileInfo = (tileLayout: ITileLayout): ITileInfo[] => {
    const layout: ITileInfo[] = [];
    const viewportInputArray: any[] = cloneDeep(FUSION_VIEWPORT_CONFIG[tileLayout.layoutString]);
    for (let index = 0; index < viewportInputArray.length; index++) {
      const stack: ITileInfo = {
        tileId: viewportInputArray[index].viewportId,
        tileIndex: `${this.stackIndex}${VIEWPORT_INDEX_SEPERATOR}${index}`,
        viewportType: viewportInputArray[index].type,
        config: viewportInputArray[index],
      };
      layout.push(stack);
    }
    return layout;
  };

  /**
   * Unsubscribes from the auto play and download image subscriptions.
   */
  private _unsubscribeRenderer = () => {
    this._autoPlaySubs$?.unsubscribe();
    this._downloadImageSubs$?.unsubscribe();
    this._displayViewportSubs$?.unsubscribe();
  };

  /**
   * Displays the image in the viewport based on the given tile layout.
   * @param {TileLayout} tileLayout - The tile layout to be used for displaying the image.
   * @returns {Promise<void>} - A promise that resolves once the image is displayed.
   */
  private _displayImage = async (tileLayout: ITileLayout, viewportInfo: ISeriesToStack): Promise<void> => {
    // Load data to viewport base on type
    if (viewportInfo) {
      const type = viewportInfo.mode;
      switch (type) {
        case ViewerMode.Stack2D:
          await this._displayStackViewport(tileLayout);
          break;
        case ViewerMode.MPR:
          await this._displayMPR();

          break;
        case ViewerMode.Fusion:
          await this._displayFusion();
          break;
      }
    } else {
      //remove all viewport if alredy render before
      this.cornerstoneService.disableViewportByStackIndex(this.stackIndex);
      this.viewportInfo = undefined;
    }
  };

  //#endregion

  //#region Load and display Image

  //#region  Stack viewport type

  /**
   * Loads the stack for the given series UID.
   * If the provider is Google, it starts downloading the remaining images on Google Drive.
   * @param {string} seriesUid - The UID of the series.
   * @returns {Promise<void>} - A promise that resolves when the stack is loaded.
   */
  private async _loadStack(seriesUid: string): Promise<void> {
    // Get the provider of the series
    const provider = <Provider>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackProvider(seriesUid)));
    //Display images
    if (provider === Provider.Google) {
      // Start download remaining image on google drive
      this.downloadedImage$ = this.store.select(ViewerGeneralQuery.selectDownloadedImageInStack(seriesUid));
      this._downloadImageSubs$ = this.downloadedImage$.subscribe((images) => this._downloadImage(images));
    }
  }

  /**
   * Displays the stack viewport with the given tile layout.
   * @param tileLayout The tile layout for the stack viewport.
   * @returns A promise that resolves when the stack viewport is displayed.
   */
  private _displayStackViewport = async (tileLayout: ITileLayout) => {
    try {
      const imageData = <IStackImage>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUId(this.seriesUid)));
      const seriesInfos = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesInfoByUid(this.seriesUid)));
      //Get image url for each tile
      if (imageData && tileLayout && seriesInfos) {
        // if (CPU_RENDER_MODALITY.includes(imageData.imagePrefetch[0].tag.general.modality)) {
        //   this.cornerstoneService.useCPURendering(true);
        // }
        //Display image to tile component
        const imageIds = imageData.imagePrefetch.map((img) => img.imageId);
        let totalImages = parseInt(seriesInfos.totalImage, 10);
        if (imageData.imagePrefetch[0].isMultiFrame) {
          totalImages = imageData.imagePrefetch.length;
        }
        if (this._currentImageIds.length === 0) {
          this._currentImageIds = imageIds;
        }
        // remove images from the cache to be able to re-load them
        imageIds.forEach((imageId) => {
          if (cache.getImageLoadObject(imageId)) {
            cache.removeImageLoadObject(imageId);
          }
        });
        let index = 0;
        this.studyUid = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStudyUidBySeriesUid(this.seriesUid)));
        for (let tile of this.tileChildren) {
          if (index > imageIds.length) {
            break;
          }

          //multi tile, we seperate image id to difference array to attach to viewport
          const lastImageIdIndex = tileLayout.total < imageIds.length ? tileLayout.total : imageIds.length;
          if (tileLayout.total > 1 && imageIds.length > 1) {
            //remove image url begin of array because display on before tile
            const newImageIds = cloneDeep(imageIds);
            newImageIds.splice(0, index);
            //remove image url end of array because display on after tile
            if (index < lastImageIdIndex - 1) {
              newImageIds.splice(-(lastImageIdIndex - index - 1));
            }
            await tile.onRender2D(this.seriesUid, this.studyUid, newImageIds, totalImages);
          } else {
            await tile.onRender2D(this.seriesUid, this.studyUid, imageIds, totalImages);
          }
          index++;
          if (imageIds.length === 1) {
            break;
          }
        }
        this.totalImages = imageIds.length;
      }
    } catch (error) {
      log.error(error);
    }
  };

  /**
   * Downloads the specified images and updates the total downloaded and total image count.
   * @param imageIds - An array of image IDs to download.
   * @returns A promise that resolves when the images are downloaded.
   */
  private _downloadImage = async (imageIds: string[] | undefined) => {
    if (imageIds === undefined || imageIds.length === 1) {
      return;
    }
    if (this._currentImageIds.length > 1 && this._currentImageIds[1] === imageIds[1]) {
      return;
    } else {
      this._currentImageIds = imageIds;
    }
    const tileLayout = await firstValueFrom(this.tileLayout$);
    let index = 0;
    for (let tile of this.tileChildren) {
      //multi tile, we seperate image id to difference array to attach to viewport
      if (tileLayout && tileLayout.total > 1) {
        //remove image url begin of array because display on before tile
        const newImageIds = cloneDeep(imageIds);
        newImageIds.splice(0, index);
        //remove image url end of array because display on after tile
        if (index < tileLayout.total - 1) {
          newImageIds.splice(-(tileLayout.total - index - 1));
        }
        tile.updateImage(newImageIds, imageIds.length, this.seriesUid, this.studyUid);
      } else {
        tile.updateImage(imageIds, imageIds.length);
      }
      index++;
    }

    if (this.tileChildren.length > 1) {
      this.totalImages = imageIds.length - (tileLayout?.total ?? 0) || 1;
    } else {
      this.totalImages = imageIds.length;
    }
  };

  /**
   * Handles the change of the display viewport.
   * @param viewport - The viewport to be displayed.
   * @returns A Promise that resolves when the display viewport is updated.
   */
  private _onChangeDisplayViewport = async (isChangeAll: boolean) => {
    //Not change display viewport when not activated and on case change only one stack
    if (!isChangeAll && !this.activated) {
      return;
    }
    const viewport = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackViewportType(this.stackIndex)));
    //in case close stack action
    if (!viewport) {
      //close stack
      //remove all tile is rendering
      this.activated = false;
      this.tileInfo = [];
      this.viewportInfo = undefined;
      this.seriesUid = '';
      this.totalImages = 0;
      this.imageIndex = -1;
      this._unsubscribeRenderer();
      return;
    }

    if (this.viewportInfo?.seriesUid === viewport?.seriesUid) {
      return;
    }

    //Check if viewport is change on next and previous series
    const tileLayout = <ITileLayout>await firstValueFrom(this.tileLayout$);
    this.tileInfo = [];
    this._renderedImage = false;
    await this._onTileLayoutChange(tileLayout);
    this.store.dispatch(
      layoutActions.changeActiveStack({
        activeStack: {
          seriesUid: this.seriesUid,
          stackIndex: this.stackIndex,
          layout: this.stackLayout,
        },
        mode: this.viewportInfo?.mode || ViewerMode.Stack2D,
      }),
    );
  };
  //#endregion

  //#region  MPR FUSION Viewport type orthographic

  private _displayMPR = async (): Promise<void> => {
    try {
      // Set all the viewports
      this.toolsService.removeTools(this.stackIndex, ViewerMode.MPR);
      this.cornerstoneService.disableVolumeViewports();
      // MPR only generate volume by one series, but the series uid for volume is build combine 2 series uid CT and PET for fusion
      //So in MPR mode we need to get volume id by series uid of CT or PET at first value when spilt the series uid recieve from store
      const seriesUid = this.seriesUid.split(ORTHOGRAPHIC_STACK_SEPERATOR)[0];
      this.studyUid = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStudyUidBySeriesUid(seriesUid)));
      const volumeId = await firstValueFrom(this.store.select(CornerstoneQuery.selectVolumeBySeriesUid(seriesUid)));
      // Get modality from volume series uid
      const modality = seriesUid.split(SERIES_UID_VOLUME_SEPERATOR)[MODALITY_INDEX];
      const renderTask: Promise<boolean>[] = [];
      for (let tile of this.tileChildren) {
        //multi tile, we seperate image id to difference array to attach to viewport
        renderTask.push(tile.onRenderMPR(seriesUid, this.studyUid, modality, volumeId));
      }
      await Promise.all(renderTask);
      const viewportIds = this.tileChildren.map((tile) => tile.tileId);
      //Enable tools
      this.toolsService.activeToolToMPRViewport(this.stackIndex, viewportIds, volumeId);
      this.toolsService.initDefaultToolVolume();
      this.sharedService.preloader(false);
    } catch (error) {
      log.error(error);
    }
  };

  /**
   * Displays the fusion viewports based on the provided tile layout.
   *
   * @param tile - The tile layout configuration.
   * @returns A promise that resolves when the fusion viewports are displayed.
   */
  private _displayFusion = async (): Promise<void> => {
    try {
      // Set all the viewports
      this.synchronizerService.removeVolumeCameraPositionSynchronizer(this.stackIndex);
      await this.toolsService.removeTools(this.stackIndex, ViewerMode.Fusion);
      this.cornerstoneService.disableVolumeViewports();
      // Combine 2 series uid CT and PET for fusion
      const seriesUids: string[] = this.seriesUid.split(ORTHOGRAPHIC_STACK_SEPERATOR);
      const ctSeries: string = seriesUids.find((uid) => uid.includes(MODALITY_NAME.CT)) || '';
      const ptSeries: string = seriesUids.find((uid) => uid.includes(MODALITY_NAME.PT)) || '';
      this.studyUid = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStudyUidBySeriesUid(ctSeries)));
      const ctVolumeId: string = await firstValueFrom(this.store.select(CornerstoneQuery.selectVolumeBySeriesUid(ctSeries)));
      const ptVolumeId: string = await firstValueFrom(this.store.select(CornerstoneQuery.selectVolumeBySeriesUid(ptSeries)));
      const viewportIds: string[] = this.tileChildren.map((tile) => tile.tileId);
      const ctViewportIds = viewportIds.filter((viewport) => viewport.includes(FUSION_CT_ID));
      const ptViewportIds = viewportIds.filter((viewport) => viewport.includes(FUSION_PT_ID));
      const fusionViewportIds = viewportIds.filter((viewport) => viewport.includes(FUSION_ID));
      const mipViewportIds = viewportIds.filter((viewport) => viewport.includes(FUSION_MIP_ID));
      if (viewportIds.length > 0) {
        const task: Promise<boolean>[] = [];
        for (let tile of this.tileChildren) {
          task.push(tile.onRenderFusion(this.seriesUid, this.studyUid, ctVolumeId, ptVolumeId));
        }
        await Promise.all(task);
        if (ctViewportIds.length > 0) {
          this.toolsService.addDefaultToolsCT(this.stackIndex, ctViewportIds);
        }
        if (ptViewportIds.length > 0) {
          this.toolsService.addDefaultToolsPT(this.stackIndex, ptViewportIds);
        }
        if (fusionViewportIds.length > 0) {
          this.toolsService.addDefaultToolsFusion(this.stackIndex, fusionViewportIds, ctVolumeId);
        }
        if (mipViewportIds.length > 0) {
          this.toolsService.addDefaultToolsMIP(this.stackIndex, mipViewportIds[0]);
        }
      }
      const axialViewport = this.tileChildren.filter((tile) => tile.tileId.includes(ORIENTATION.AXIAL)).map((tile) => tile.tileId);
      const sagittalViewport = this.tileChildren.filter((tile) => tile.tileId.includes(ORIENTATION.SAGITTAL)).map((tile) => tile.tileId);
      const coronalViewport = this.tileChildren
        .filter((tile) => tile.tileId.includes(ORIENTATION.CORONAL))
        .map((tile) => tile.tileId)
        .filter((tile) => !tile.includes(FUSION_MIP_ID));

      if (axialViewport.length > 0) {
        this.synchronizerService.createVolumeCameraPositionSynchronizer(ORIENTATION.AXIAL, this.stackIndex, axialViewport);
      }
      if (sagittalViewport.length > 0) {
        this.synchronizerService.createVolumeCameraPositionSynchronizer(ORIENTATION.SAGITTAL, this.stackIndex, sagittalViewport);
      }
      if (coronalViewport.length > 0) {
        this.synchronizerService.createVolumeCameraPositionSynchronizer(ORIENTATION.CORONAL, this.stackIndex, coronalViewport);
      }
      this.toolsService.initDefaultToolVolume();
      this.sharedService.preloader(false);
    } catch (error) {
      log.error(error);
    }
  };
  //#endregion

  //#endregion

  //#region Layout area
  /**
   * Returns the X coordinate of the stack layout.
   *
   * @returns The X coordinate of the stack layout.
   */
  protected getStackX() {
    return parseInt(this.stackLayout.charAt(LAYOUT_COLS_INDEX));
  }

  /**
   * Returns the Y coordinate of the stack layout.
   *
   * @returns The Y coordinate of the stack layout.
   */
  protected getStackY() {
    return parseInt(this.stackLayout.charAt(LAYOUT_ROWS_INDEX));
  }

  /**
   * Retrieves the X coordinate of the tile layout.
   *
   * @param layout - The layout string representing the tile layout.
   * @returns The X coordinate of the tile layout.
   */
  protected getTileX(layout) {
    if (layout) {
      return parseInt(layout.charAt(LAYOUT_COLS_INDEX));
    }
    return 0;
  }

  /**
   * Retrieves the Y coordinate of the tile layout.
   *
   * @param layout - The layout string representing the tile configuration.
   * @returns The Y coordinate of the tile layout.
   */
  protected getTileY(layout) {
    if (layout) {
      return parseInt(layout.charAt(LAYOUT_ROWS_INDEX)) || 0;
    }
    return 0;
  }

  /**
   * Returns the style object for the stack component.
   * The style object includes the height and width of the stack component,
   * as well as any additional border styles based on the stack's position in the layout.
   *
   * @returns The style object for the stack component.
   */
  protected getStyle() {
    const cols = parseInt(this.stackLayout.charAt(LAYOUT_COLS_INDEX)) || 0;
    const row = Math.floor(this.index / cols);
    const col = this.index % cols;
    const borderProp = '1px solid var(--bs-white)';

    const style: any = {};
    if (row !== 0) {
      style.borderTop = borderProp;
    }

    if (col !== 0) {
      style.borderLeft = borderProp;
    }

    if (this.viewportInfo?.mode === ViewerMode.MPR || this.viewportInfo?.mode === ViewerMode.Fusion) {
      style.borderTop = borderProp;
      style.borderLeft = borderProp;
    }

    return { height: 100 / this.getStackY() + '%', width: 100 / this.getStackX() + '%', ...style };
  }

  /**
   * Retrieves the style object for a 2D tile based on its index and layout.
   * @param index - The index of the tile.
   * @param layout - The layout of the tiles.
   * @returns The style object for the tile.
   */
  protected getTile2DStyle(index: number, layout: string) {
    const cols = parseInt(layout.charAt(LAYOUT_COLS_INDEX)) || 0;
    const row = Math.floor(index / cols);
    const col = index % cols;
    const borderProp = '1px solid var(--bs-white)';

    const style: any = {};
    if (row !== 0) {
      style.borderTop = borderProp;
    }

    if (col !== 0) {
      style.borderLeft = borderProp;
    }
    style.display = 'block';
    style.float = 'left';
    return {
      height: 100 / this.getTileY(layout) + '%',
      width: 100 / this.getTileX(layout) + '%',
      ...style,
    };
  }

  /**
   * Retrieves the volume style based on the specified layout.
   *
   * @param layout - The layout string representing the number of columns and rows.
   * @returns The volume style object.
   */
  protected getVolumeStyle(layout: string) {
    const cols = parseInt(layout.charAt(LAYOUT_COLS_INDEX)) || 0;
    const rows = parseInt(layout.charAt(LAYOUT_ROWS_INDEX)) || 0;

    const style: any = {};
    style.display = 'block';
    style.float = 'left';
    const borderProp = '1px solid var(--bs-white)';
    if (rows !== 0) {
      style.borderBottom = borderProp;
    }
    if (cols !== 0) {
      style.borderRight = borderProp;
    }
    if (cols === 1 && rows === 1) {
      return {
        height: 100 + '%',
        width: 100 + '%',
        ...style,
      };
    }

    if (cols > 1 && rows == 1) {
      return {
        height: 100 + '%',
        width: 100 / this.getTileX(layout) + '%',
        ...style,
      };
    }

    if (rows > 1 && cols === 1) {
      return {
        height: 100 / this.getTileY(layout) + '%',
        width: 100 + '%',
        ...style,
      };
    }
    return {
      height: 100 / this.getTileY(layout) + '%',
      width: 100 / this.getTileX(layout) + '%',
      ...style,
    };
  }
  /**
   * Changes the active stack based on the current selected stack.
   * If the current selected stack is different from the current stack index,
   * it dispatches actions to change the selected panel and selected stack.
   * It also triggers a change in the download series queue.
   */
  private async _changeActiveStack() {
    const currentActiveStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveStack));
    if (currentActiveStack?.stackIndex !== this.stackIndex) {
      this.store.dispatch(
        layoutActions.changeActiveStack({
          activeStack: {
            seriesUid: this.seriesUid,
            stackIndex: this.stackIndex,
            layout: this.stackLayout,
          },
          mode: this.viewportInfo?.mode || ViewerMode.Stack2D,
        }),
      );
    }
  }
  //#endregion
  //#region HTML Events
  /**
   * Handles the drop event for series in the stack component.
   * @param $event - The drop event object.
   */
  protected seriesDrop = async ($event) => {
    const data = JSON.parse($event.dataTransfer.getData('dataDrop'));
    // Not allow drop series to stack in MPR/ Fusion mode
    if (this.viewportInfo !== undefined && this.viewportInfo.mode !== ViewerMode.Stack2D) {
      this.sharedService.toastMessage(Severity.warn, this.translate.instant('NotSupportDropSeries'));
      return;
    }
    if (data && data.seriesInfo) {
      $event.preventDefault();
      this.activated = true;
      this.store.dispatch(
        viewerGeneralActions.replaceSeriesInStack({
          info: {
            studyUid: data.seriesInfo.studyUid,
            seriesUid: data.seriesInfo.uid,
            stackIndex: this.stackIndex,
            type: Enums.ViewportType.STACK,
            mode: ViewerMode.Stack2D,
          },
        }),
      );
    }
  };

  /**
   * Handles the change event of the slider.
   * @param e - The event object.
   */
  protected handleSliderChanged = async (e: any) => {
    if (e?.target?.value) {
      this.imageIndex = e.target.value;
      //get current viewport
      const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
      const currentIndex = viewport.getCurrentImageIdIndex();
      const delta = this.imageIndex - currentIndex;
      await this._scrollImage(delta, viewport, false);
    }
  };
  //#endregion
  //#region HostListener
  /**
   * Handles the click event for the stack component.
   * Updates the selected panel and stack based on the clicked stack index.
   */
  @HostListener('click', ['$event'])
  onClick = async ($event: MouseEvent) => {
    // only processing in stack have image
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }
    this._changeActiveStack();
    if ($event.ctrlKey) {
      this.store.dispatch(
        layoutActions.updateSelectedStack({
          selectedStack: { seriesUid: this.seriesUid, stackIndex: this.stackIndex },
        }),
      );
    }
  };

  @HostListener('dblclick', ['$event'])
  onDbClick = async ($event: MouseEvent) => {
    if (!this.activated) {
      return;
    }
    // only processing in stack have image
    if (this.viewportInfo === undefined) {
      return;
    }
    const tileLayout = await firstValueFrom(this.tileLayout$);
    this.store.dispatch(
      viewerGeneralActions.activeFullImage({
        displayViewport: this.viewportInfo as ISeriesToStack,
        tileLayout: tileLayout?.layoutString || DEFAULT_TILE_LAYOUT,
      }),
    );
  };

  /**
   * Handles the mouse wheel event.
   * @param $event - The mouse wheel event object.
   */
  @HostListener('mousewheel', ['$event'])
  protected onMouseWheel = async ($event) => {
    // only processing in stack have image
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }
    this._changeActiveStack();
    if (this.viewportInfo?.type === Enums.ViewportType.STACK) {
      //Apply Scroll image
      await this._scrollImageByMouseWheel($event);
    }
  };

  /**
   * Handles the mouse down event.
   * @param $event - The mouse event object.
   */
  @HostListener('mousedown', ['$event'])
  protected onMouseDown = async ($event: MouseEvent) => {
    if (this.activated && this.viewportInfo) {
      const activeTool = await firstValueFrom(this.activeTool$);
      if (activeTool === Tools.Scroll && $event.button === MouseButton.Left) {
        this._isMousePress = true;
        this._mouseDownPosition = {
          x: $event.pageX,
          y: $event.pageY,
        };
      }
    }
  };

  /**
   * Handles the mouse up event.
   * @param event - The MouseEvent object.
   */
  @HostListener('window:mouseup', ['$event'])
  protected onMouseUp(event: MouseEvent) {
    if (this._isMousePress) {
      this._isMousePress = false;
      event.stopImmediatePropagation();
    }
  }

  @HostListener('window:mousemove', ['$event'])
  protected onMouseMove($event: MouseEvent) {
    if (this._isMousePress) {
      this._scrollImageByMouse($event);
      $event.stopImmediatePropagation();
    }
  }

  /**
   * Handles the keydown event for the stack component.
   * @param $event - The keyboard event object.
   */
  @HostListener('window:keydown', ['$event'])
  protected onKeyDown = async ($event: KeyboardEvent) => {
    if (this.activated && this.viewportInfo) {
      switch ($event.key) {
        case KeyName.ArrowUp:
        case KeyName.ArrowDown:
          await this._scrollImageByKey($event);
          break;
        case KeyName.Delete:
          await this._deleteAnnotationMeasure();
          break;
        default:
          break;
      }
    }
  };

  /**
   * Handles scrolling of images based on keyboard events.
   * @param $event - The keyboard event.
   */
  private _scrollImageByKey = async ($event: KeyboardEvent) => {
    if (!this._isKeyPress) {
      let isIncreateSlice;
      const isLoop = await firstValueFrom(this.isScrollLoop$);
      if ($event.key === KeyName.ArrowUp) {
        isIncreateSlice = false;
      } else if ($event.key === KeyName.ArrowDown) {
        isIncreateSlice = true;
      } else {
        return;
      }

      //get current viewport

      const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
      const delta = await this.calculateDelta(isIncreateSlice);
      await this._scrollImage(delta, viewport, isLoop);
      //Debound spam press key for smooth scroll slice
      setTimeout(() => {
        this._isKeyPress = false;
      }, 10);
    }
  };

  /**
   * Handles the scroll event triggered by the mouse wheel.
   * @param $event - The mouse wheel event object.
   */
  private _scrollImageByMouseWheel = async ($event: any) => {
    let isIncreateSlice;
    const isLoop = await firstValueFrom(this.isScrollLoop$);
    if ($event.wheelDelta < 0 || $event.detail > 0) {
      isIncreateSlice = true;
    } else {
      isIncreateSlice = false;
    }

    //get current viewport

    const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
    const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
    const delta = await this.calculateDelta(isIncreateSlice);
    await this._scrollImage(delta, viewport, isLoop);
  };

  /**
   * Calculates the delta value based on the scroll mode and tile layout.
   * @returns A Promise that resolves to the calculated delta value.
   */
  private calculateDelta = async (isIncreateSlice: boolean): Promise<number> => {
    const scrollMode = await firstValueFrom(this.store.select(ViewerMenuQuery.selectScrollMode));
    const tileLayout = await firstValueFrom(this.tileLayout$);
    if (tileLayout === undefined || tileLayout.total === 1) {
      return isIncreateSlice ? 1 : -1;
    }

    switch (scrollMode) {
      case ScrollMode.OneImage:
        return isIncreateSlice ? 1 : -1;
      case ScrollMode.OneImageRow:
        const tileColums = parseInt(tileLayout.layoutString.charAt(LAYOUT_COLS_INDEX));
        return isIncreateSlice ? tileColums : -tileColums;
      case ScrollMode.OneImagePage:
        const totalTile = tileLayout.total;
        return isIncreateSlice ? totalTile : -totalTile;
      default:
        return isIncreateSlice ? 1 : -1;
    }
  };

  /**
   * Handles scrolling of images by mouse movement.
   *
   * @param $event - The MouseEvent object representing the mouse event.
   * @returns A Promise that resolves when the scrolling is complete.
   */
  private _scrollImageByMouse = async ($event: MouseEvent) => {
    if (this._isMousePress) {
      let isIncreateSlice;
      const isLoop = await firstValueFrom(this.isScrollLoop$);
      const mouseMoveDistance = $event.pageY - this._mouseDownPosition.y;
      if (mouseMoveDistance >= 0 && mouseMoveDistance > MOUSE_MOVE_DISTANCE) {
        isIncreateSlice = true;
      } else if (mouseMoveDistance < 0 && Math.abs(mouseMoveDistance) > MOUSE_MOVE_DISTANCE) {
        isIncreateSlice = false;
      } else {
        //Only scroll when mouse move distance greater than 10px and by y vertical
        return;
      }
      //get current viewport
      const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
      const delta = await this.calculateDelta(isIncreateSlice);
      await this._scrollImage(delta, viewport, isLoop);
      this._mouseDownPosition.y = $event.pageY;
    }
  };

  /**
   * Scrolls the image in the stack viewport.
   *
   * @param delta - The amount of scrolling to apply.
   * @param viewport - The viewport to scroll the image in.
   * @param isLoop - A boolean indicating whether the scrolling should loop.
   */
  private _scrollImage = async (delta: number, viewport: Types.IViewport, isLoop: boolean) => {
    let volumeId: string = '';
    //change image index on stack viewport
    if (this.cornerstoneService.isVolumeViewport(viewport)) {
      volumeId = await firstValueFrom(this.store.select(CornerstoneQuery.selectVolumeBySeriesUid(this.seriesUid)));
      toolUti.scroll(viewport, {
        delta,
        debounceLoading: false,
        loop: isLoop,
        volumeId,
        scrollSlabs: false,
      });
    } else {
      const stackViewport = <csTypes.IStackViewport>viewport;
      const imageIndex = stackViewport.getCurrentImageIdIndex() + delta;
      await stackScroll(stackViewport, imageIndex, isLoop, delta);
    }
  };

  /**
   * Scrolls the stack viewport to the specified image index.
   * If the viewport is a volume viewport, it also retrieves the volume ID based on the series UID.
   *
   * @param imageIndex - The index of the image to scroll to.
   * @param viewport - The stack viewport to scroll.
   */
  private _scrollToImage = async (imageIndex: number, viewport: Types.IViewport) => {
    let volumeId: string = '';
    //change image index on stack viewport
    if (this.cornerstoneService.isVolumeViewport(viewport)) {
      volumeId = await firstValueFrom(this.store.select(CornerstoneQuery.selectVolumeBySeriesUid(this.seriesUid)));
    }

    toolUti.jumpToSlice(viewport.element, {
      imageIndex,
      volumeId,
    });
  };

  /**
   * Deletes the annotation measure and renders the viewport.
   */
  private _deleteAnnotationMeasure = async () => {
    this.toolsService.removeAnnotationMeasure();
    const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
    const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
    if (!viewport) {
      return;
    }
    viewport.render();
  };

  //#endregion

  //#region Broadcast subscription handler
  // private _onVolumeCameraBroadcast = async (value: IVolumeCameraBroadcast) => {
  //   if (this.viewportInfo === undefined) {
  //     return;
  //   }
  //   if (value.scope === BroadcastScope.ActiveTile) {
  //     //get current active tile viewport
  //     const viewport = <csTypes.IVolumeViewport>this.cornerstoneService.getVolumeViewportById(this._activeTile);
  //   } else {
  //     const tileLayout = (await firstValueFrom(this.tileLayout$)) as ITileLayout;
  //     if (this.viewportInfo?.mode === ViewerMode.MPR) {
  //       await this._displayMPR(tileLayout);
  //     } else {
  //       await this._displayFusion(tileLayout);
  //     }
  //   }
  // };

  /**
   * Handles the auto play broadcast event.
   * @param payload - The payload containing the action and other information.
   */
  private _onAutoPlayBroadcast = async (payload) => {
    if (payload.action === AutoPlay.Play && this._isAutoPlay) {
      //get viewport
      const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
      //stop play loop
      toolUti.cine.stopClip(viewport.element);
      this._isAutoPlay = false;
      return;
    }

    if (this.activated) {
      const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getViewport(activeTile?.tileIndex || '0-0-0');
      let delta;
      switch (payload.action) {
        case AutoPlay.FirstImage:
          this._scrollToImage(0, viewport);
          this.imageIndex = 0;
          break;
        case AutoPlay.PreviousImage:
          delta = -1;
          this._scrollImage(delta, viewport, false);
          break;
        case AutoPlay.NextImage:
          delta = 1;
          this._scrollImage(delta, viewport, false);
          break;
        case AutoPlay.LastImage:
          let imageIndex = 0;
          if (this.cornerstoneService.isStackViewport(viewport)) {
            imageIndex = viewport.getImageIds().length - 1;
          }
          this._scrollToImage(imageIndex, viewport);
          this.imageIndex = imageIndex;
          break;
        case AutoPlay.Play:
          const isLoop = await firstValueFrom(this.isScrollLoop$);
          if (isLoop) {
            const currentIndex = viewport.getCurrentImageIdIndex();
            const totalImages = viewport.getNumberOfSlices();
            if (currentIndex === totalImages - 1) {
              this._scrollToImage(0, viewport);
              this.imageIndex = 0;
            }
          }
          toolUti.cine.playClip(viewport.element, { loop: isLoop, framesPerSecond: payload.fps });
          const state = toolUti.cine.getToolState(viewport.element);
          if (state) {
            state.loop = isLoop;
          }
          this._isAutoPlay = true;
          viewport.element.addEventListener(toolUti.cine.Events.CLIP_STOPPED, (event: any) => this._onCineStop(event.detail.element));
          break;
        default:
          break;
      }
    }
  };

  /**
   * Event handler for the cine stop event.
   * @param {any} element - The element associated with the event.
   */
  private _onCineStop = (element) => {
    this._isAutoPlay = false;
    this.broadcastService.autoPlayBroadcast(AutoPlay.Stop);
    toolUti.cine.stopClip(element);
    this._isAutoPlay = false;
    element.removeEventListener(toolUti.cine.Events.CLIP_STOPPED, this._onCineStop);
  };

  /**
   * Exports the current image as a JPEG file.
   * This method is asynchronous.
   */
  private _exportImage = async () => {
    if (this.activated && this.viewportInfo?.type === Enums.ViewportType.STACK) {
      const activeTile = <IActiveTile>await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
      const viewport = this.cornerstoneService.getStackViewport(activeTile.tileIndex);
      if (viewport === null) {
        return;
      }
      const image: IImage = viewport.getCornerstoneImage() as IImage;
      const canvas = viewport.canvas;
      const imageUrl = canvas.toDataURL('image/jpeg');
      const instanceNumber = image.data.string('x00200013');
      const dlLink = document.createElement('a');
      dlLink.download = `${image.file?.name || `${this.seriesUid}-${instanceNumber}`}.jpeg`;
      dlLink.href = imageUrl;
      dlLink.dataset.downloadurl = [`image/jpeg`, dlLink.download, dlLink.href].join(':');
      document.body.appendChild(dlLink);
      dlLink.click();
      document.body.removeChild(dlLink);
    }
  };

  //#endregion

  //#region  Tile component event
  protected onTileScrollEvent = (index: number) => {
    this.imageIndex = index;
  };
  //#endregion
}
