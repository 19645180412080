const STUDY_DURATION = {
  TODAY: 'wlToday',
  YESTERDAY: 'wlYesterday',
  LAST_7_DAYS: 'wlLast1Week',
  LAST_1_MOUNTHS: 'wlLast1Month',
  LAST_3_MONTHS: 'wlLast3Month',
  LAST_6_MONTHS: 'wlLast6Month',
  LAST_1_YEAR: 'wlLast1Year',
  LAST_3_YEARS: 'wlLast3Year',
  LAST_5_YEARS: 'wlLast5Year',
  LAST_10_YEARS: 'wlLast10Year',
  LAST_20_YEARS: 'wlLast20Year',
  LAST_30_YEARS: 'wlLast30Year',
};

const STUDY_DURATION_VALUE = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_1_MOUNTHS: 'LAST_1_MOUNTHS',
  LAST_3_MONTHS: 'LAST_3_MONTHS',
  LAST_6_MONTHS: 'LAST_6_MONTHS',
  LAST_1_YEAR: 'LAST_1_YEAR',
  LAST_3_YEARS: 'LAST_3_YEARS',
  LAST_5_YEARS: 'LAST_5_YEARS',
  LAST_10_YEARS: 'LAST_10_YEARS',
  LAST_20_YEARS: 'LAST_20_YEARS',
  LAST_30_YEARS: 'LAST_30_YEARS',
};

export { STUDY_DURATION, STUDY_DURATION_VALUE };
